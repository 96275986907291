import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { cartActionCreators } from "../../redux/cart";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { useMediaQuery } from "react-responsive";
import { Button, Form, Select} from "antd";
// import { ExclamationCircleFilled, ShoppingOutlined } from "@ant-design/icons";
import formatter from "../../utils/formatter";
import triggerNotification from "../../hooks/triggerNotification";

import AuthModal from "../../components/AuthModal";

import colors from "../../assets/colors";


import {
  getSavedItemToLocalStorage,
  deleteSavedItemFromLocalStorage,
} from "../../utils/guestSavedProductsLocal";

const { Option } = Select;

const ProductInSavedItems = ({ item, deleteItem, itemsInfo }) => {
  const { data } = useSelector((state) => state.currency);
  const { rate, code, indicator } = data;

  const dispatch = useDispatch()

    const { items: productInCart } = useSelector((state) => state.cart);

    const { addToCart, updateProductAlreadyInCart } = bindActionCreators(
      cartActionCreators,
      dispatch
    );

  const [itemColors, setItemColors] = useState([]);
  const [itemSizes, setItemSizes] = useState([]);


  const getItemColors = (id) => {
    const item = itemsInfo.find((item) => item.id === id);
    const colors = item?.info.map((info) => info.color);
    // console.log('itemColor', colors)
    setItemColors(colors);
  };
  const getItemSizes = (selectedColor, id) => {
    const item = itemsInfo.find((item) => item.id === id);
    const info = item.info.find((info) => info.color === selectedColor);
    setItemSizes(info.sizes);
  };

  const [form] = Form.useForm();

  const addProductToCart = (values) => {
    const selectedColor = colors.find(
      (color) => color?.name?.toLowerCase() === values?.colorSelect?.toLowerCase()
    );

    const clonedCartItems = JSON.parse(JSON.stringify(productInCart));

    const productAlreadyInCartIndex = clonedCartItems.findIndex((cartItem) => {
      return (
        cartItem?.id === item?.id &&
        cartItem?.selectedColor?.hex?.toLowerCase() ===
          selectedColor?.hex?.toLowerCase() &&
        cartItem?.selectedSize === values?.sizeSelect
      );
    });

    if(productAlreadyInCartIndex !== -1) {
      const clonedItemAlreadyInCart = {
        ...clonedCartItems[productAlreadyInCartIndex],
      };
      clonedItemAlreadyInCart.quantityWanted++;
      clonedItemAlreadyInCart.totalPrice =
        clonedItemAlreadyInCart.price * clonedItemAlreadyInCart.quantityWanted;
      updateProductAlreadyInCart(clonedItemAlreadyInCart);
      triggerNotification({
        type: "success",
        message: "Item quantity updated in your shopping bag",
      });
    } else {
      const tempProductForCart = JSON.parse(JSON.stringify(item));
      tempProductForCart.quantityWanted = 1;
      tempProductForCart.totalPrice = JSON.parse(JSON.stringify(item)).price;
      tempProductForCart.selectedColor = selectedColor;
      tempProductForCart.selectedSize = values?.sizeSelect;
      addToCart(tempProductForCart);
      triggerNotification({
        type: "success",
        message: "Item has been added to your shopping bag",
      });
    }

  }

  useEffect(() => {
    form.resetFields();
    if (itemColors?.length === 1) {
      form.setFieldsValue({
        colorSelect: itemColors[0],
      });
      getItemSizes(itemColors[0], item.product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, itemColors, itemSizes]);

  useEffect(() => {
    getItemColors(item.product_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemsInfo]);

  return (
    <Form
      className="add-to-cart-form"
      form={form}
      onFinish={addProductToCart}
      scrollToFirstError
    >
      <img
        alt={item.name}
        className="product-img"
        src={item.img1}
      />
      <svg
        className="icon-close"
        width="30"
        height="30"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => deleteItem(item.product_id)}
      >
        <path
          d="M20.8258 0.583008C9.36216 0.583008 0.25 9.69517 0.25 21.1588C0.25 32.6225 9.36216 41.7347 20.8258 41.7347C32.2895 41.7347 41.4017 32.6225 41.4017 21.1588C41.4017 9.69517 32.2895 0.583008 20.8258 0.583008ZM28.7622 31.4468L20.8258 23.5104L12.8894 31.4468L10.5379 29.0952L18.4743 21.1588L10.5379 13.2225L12.8894 10.8709L20.8258 18.8073L28.7622 10.8709L31.1138 13.2225L23.1774 21.1588L31.1138 29.0952L28.7622 31.4468Z"
          fill="black"
        />
      </svg>
      <div className="saved-item-details">
        <h4 className="saved-item-name">{item.name}</h4>
        <h4 className="saved-item-price">
          {formatter(item.price, rate, code, indicator)}
        </h4>
        <Form.Item
          name="colorSelect"
          rules={[
            {
              required: itemColors?.length > 0 ? true : false,
              message: "Please select a color",
            },
          ]}
        >
          <Select
            className="saved-item-select-option"
            placeholder="Select Color"
            // onFocus={() => getItemColors(item.product_id)}
            onChange={(val) => getItemSizes(val, item.product_id)}
            // value={(itemsInfo && itemsInfo?.length>0)?
            //   () => {
            //       console.log(item?.product_id)
            //       const singleItem = itemsInfo.find((i) => i.id === item.product_id);
            //       const colors = singleItem?.info.map((info) => info.color);
            //       console.log(itemsInfo)
            //       console.log(singleItem)
            //       if (colors?.length===1){
            //         return (colors[0]);
            //       }
            //   }:""
            // }
          >
            {itemColors?.map((color, idx) => (
              <Option
                value={color}
                key={idx}
              >
                {color}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="sizeSelect"
          rules={[
            {
              required: itemSizes?.length > 0 ? true : false,
              message: "Please select a size",
            },
          ]}
        >
          <Select
            className="saved-item-select-option"
            placeholder="Select Size"
          >
            {itemSizes?.map((size, idx) => (
              <Option
                value={size}
                key={idx}
              >
                {size}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button
          className="btn-add-item"
          type="primary"
          htmlType="submit"
        >
          Add to bag
        </Button>
      </div>
    </Form>
  );
};

const SavedItems = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [itemsInfo, setItemsInfo] = useState([]);
  const [guestSavedProducts, setGuestSavedProducts] = useState([]);

  const [buyerSignInAuthModalVisible, setBuyerSignInAuthModalVisible] =
    useState(false);

  const { savedItems, info } = useSelector((state) => state.user);
  // const { data } = useSelector((state) => state.currency);
  // const { rate, code, indicator } = data;
  // const { addToCart } = bindActionCreators(cartActionCreators, dispatch);

  const { postItem } = bindActionCreators(userActionCreators, dispatch);

  const { type } = useSelector((state) => state.user);

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const { fetchItems, deleteSavedItem } = bindActionCreators(
    userActionCreators,
    dispatch
  );

  const setSizesAndColors = () => {
    const savedItemsCheck = info.token ? savedItems : guestSavedProducts;
    const items = [];
    savedItemsCheck?.map((item) => {
      let currItem = items.find((itm) => itm.id === item.product_id);
      if (!currItem)
        currItem = {
          id: item.product_id,
          info: [],
        };
      // for savedItems, variant is named varieties, while for guestSavedItems, it is named variety
      item?.[info?.token ? "varieties":"variety"]?.map((variant) => {
        if (!currItem?.info.find((itm) => itm.color === variant.color)) {
          currItem.info.push({
            color: variant.color,
            sizes: [variant.size],
          });
        } else {
          let info = currItem?.info.find((itm) => itm.color === variant.color);
          if (!info.sizes.includes(variant.size)) info.sizes.push(variant.size);
        }
        return true;
      });
      items.push(currItem);
      return true;
    });

    setItemsInfo(items);
  };

  const deleteItem = async (id) => {
    if (info.token) {
      const res = await deleteSavedItem( id);
      if (res.status === "ok") {
        triggerNotification({
          type: "success",
          message: res.message,
        });
        }
    } else {
      deleteSavedItemFromLocalStorage(id);
      const getSavedLocal = getSavedItemToLocalStorage();
      setGuestSavedProducts(getSavedLocal);
      triggerNotification({
        type: "success",
        message: "Saved Item Successfully Deleted",
      });
    }
  };

  const mergeSavedItems = async (idArr) => {
    const res = await postItem(idArr);
    if (res.status === "ok"){
      localStorage.removeItem("guestSavedItem");
    } else {
      console.error(res.status);
    } 
  }

  useEffect(() => {
    if (info.token) {
      const savedItemsLocal = JSON.parse(localStorage.getItem("guestSavedItem"));
      if(savedItemsLocal && savedItemsLocal.length > 1) {
        const savedItemsLocalIds = savedItemsLocal.map((item) => item.product_id)
        mergeSavedItems(savedItemsLocalIds);
      }
      fetchItems()
      setSizesAndColors();
    } else{
      const getSavedLocal = getSavedItemToLocalStorage();
      setGuestSavedProducts(getSavedLocal);
      setSizesAndColors();
    }
    // setSizesAndColors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.token]);

  useEffect(() => {
    setSizesAndColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedItems, guestSavedProducts])

  return (
    <div>
      {/* {!isTabletOrMobile ? ( */}
      <div
        className="saved-items-container"
        style={{ fontFamily: "DomaineSansText-Light" }}
      >
        <div className="page-details-container">
          <h1 className="page-header-title">Saved Items</h1>
          <desc className="page-desc">Save your favorite items here</desc>
          <div className="page-line" />
        </div>

        {!info.token && guestSavedProducts?.length > 0 && (
          <div className="guest-sync-container">
            <p>Sign in to sync your Saved Items across all your devices</p>
            <Button
              className="save-page-no-item-saved-button"
              onClick={() => setBuyerSignInAuthModalVisible(true)}
            >
              Register / Sign in
            </Button>
          </div>
        )}

        {info.token && savedItems && savedItems?.length > 0 ? (
          <div className="saved-items-list-container">
            <div className="sort-container">
              {/* {isTabletOrMobile && ( */}
              <div className="saved-mobile-filter-container">
                {/* <svg
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 7.5H13.5M4.5 0.5C4.76522 0.5 5.01957 0.605357 5.20711 0.792893C5.39464 0.98043 5.5 1.23478 5.5 1.5V3.5C5.5 3.76522 5.39464 4.01957 5.20711 4.20711C5.01957 4.39464 4.76522 4.5 4.5 4.5C4.23478 4.5 3.98043 4.39464 3.79289 4.20711C3.60536 4.01957 3.5 3.76522 3.5 3.5V1.5C3.5 1.23478 3.60536 0.98043 3.79289 0.792893C3.98043 0.605357 4.23478 0.5 4.5 0.5V0.5ZM16.5 2.5H5.5H16.5ZM3.5 2.5H0.5H3.5ZM4.5 10.5C4.76522 10.5 5.01957 10.6054 5.20711 10.7929C5.39464 10.9804 5.5 11.2348 5.5 11.5V13.5C5.5 13.7652 5.39464 14.0196 5.20711 14.2071C5.01957 14.3946 4.76522 14.5 4.5 14.5C4.23478 14.5 3.98043 14.3946 3.79289 14.2071C3.60536 14.0196 3.5 13.7652 3.5 13.5V11.5C3.5 11.2348 3.60536 10.9804 3.79289 10.7929C3.98043 10.6054 4.23478 10.5 4.5 10.5ZM16.5 12.5H5.5H16.5ZM3.5 12.5H0.5H3.5ZM12.5 5.5C12.7652 5.5 13.0196 5.60536 13.2071 5.79289C13.3946 5.98043 13.5 6.23478 13.5 6.5V8.5C13.5 8.76522 13.3946 9.01957 13.2071 9.20711C13.0196 9.39464 12.7652 9.5 12.5 9.5C12.2348 9.5 11.9804 9.39464 11.7929 9.20711C11.6054 9.01957 11.5 8.76522 11.5 8.5V6.5C11.5 6.23478 11.6054 5.98043 11.7929 5.79289C11.9804 5.60536 12.2348 5.5 12.5 5.5V5.5ZM11.5 7.5H0.5H11.5Z"
                      stroke="black"
                      stroke-width="0.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  // </svg> */}
                {/* <span className="filter-header-title-text">Filter </span> */}
                <span>{savedItems?.length} Items</span>
              </div>
              {/* { <Select
                className="saved-items-sort"
                defaultValue="recent"
              >
                <Select.Option value="recent">Recently Added</Select.Option>
              </Select> } */}
              {/* // {!isTabletOrMobile && (
                <h4 className="saved-item-size">{savedItems.length} items</h4>
              )} */}
            </div>

            <div className="saved-items">
              {savedItems.map((item, idx) => (
                <div
                  className="saved-item"
                  key={idx}
                >
                  <ProductInSavedItems
                    item={item}
                    deleteItem={deleteItem}
                    itemsInfo={itemsInfo}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : !info.token && guestSavedProducts && guestSavedProducts?.length > 0 ? (
          <div className="saved-items-list-container">
            <div className="sort-container">
              {/* {isTabletOrMobile && ( */}
              <div className="saved-mobile-filter-container">
                {/* <svg
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 7.5H13.5M4.5 0.5C4.76522 0.5 5.01957 0.605357 5.20711 0.792893C5.39464 0.98043 5.5 1.23478 5.5 1.5V3.5C5.5 3.76522 5.39464 4.01957 5.20711 4.20711C5.01957 4.39464 4.76522 4.5 4.5 4.5C4.23478 4.5 3.98043 4.39464 3.79289 4.20711C3.60536 4.01957 3.5 3.76522 3.5 3.5V1.5C3.5 1.23478 3.60536 0.98043 3.79289 0.792893C3.98043 0.605357 4.23478 0.5 4.5 0.5V0.5ZM16.5 2.5H5.5H16.5ZM3.5 2.5H0.5H3.5ZM4.5 10.5C4.76522 10.5 5.01957 10.6054 5.20711 10.7929C5.39464 10.9804 5.5 11.2348 5.5 11.5V13.5C5.5 13.7652 5.39464 14.0196 5.20711 14.2071C5.01957 14.3946 4.76522 14.5 4.5 14.5C4.23478 14.5 3.98043 14.3946 3.79289 14.2071C3.60536 14.0196 3.5 13.7652 3.5 13.5V11.5C3.5 11.2348 3.60536 10.9804 3.79289 10.7929C3.98043 10.6054 4.23478 10.5 4.5 10.5ZM16.5 12.5H5.5H16.5ZM3.5 12.5H0.5H3.5ZM12.5 5.5C12.7652 5.5 13.0196 5.60536 13.2071 5.79289C13.3946 5.98043 13.5 6.23478 13.5 6.5V8.5C13.5 8.76522 13.3946 9.01957 13.2071 9.20711C13.0196 9.39464 12.7652 9.5 12.5 9.5C12.2348 9.5 11.9804 9.39464 11.7929 9.20711C11.6054 9.01957 11.5 8.76522 11.5 8.5V6.5C11.5 6.23478 11.6054 5.98043 11.7929 5.79289C11.9804 5.60536 12.2348 5.5 12.5 5.5V5.5ZM11.5 7.5H0.5H11.5Z"
                      stroke="black"
                      stroke-width="0.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg> */}
                {/* <span className="filter-header-title-text">Filter </span> */}
                <span>{guestSavedProducts?.length} Items</span>
              </div>
              {/* )} */}
              {/* <Select
                className="saved-items-sort"
                defaultValue="recent"
              >
                <Select.Option value="recent">Recently Added</Select.Option>
              </Select>
              {!isTabletOrMobile && (
                <h4 className="saved-item-size">
                  {guestSavedProducts.length} items
                </h4>
              )} */}
            </div>

            <div className="saved-items">
              {guestSavedProducts.map((item, idx) => (
                <div
                  className="saved-item"
                  key={idx}
                >
                  <ProductInSavedItems
                    item={item}
                    deleteItem={deleteItem}
                    itemsInfo={itemsInfo}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : info.token ? (
          <div className="no-order-div-container">
            <div className="no-order-div">
              <svg
                width={!isTabletOrMobile ? "89" : "31"}
                height={!isTabletOrMobile ? "77" : "29"}
                viewBox="0 0 89 77"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49.5304 76.9068H39.5212L0 37.2429V12.673L12.0086 0.621094H36.49L41.3141 5.47552L36.7609 10.0452L33.82 7.09366H14.6786L6.44928 15.3527V34.5632L42.1912 70.4342H46.8604L82.5507 34.6021V15.3915L74.3086 7.11955L55.1929 7.1325L25.2683 37.2558L20.7022 32.6862L52.51 0.672874L76.9786 0.646984L89 12.7248V37.2817L49.5304 76.9068Z"
                  fill="#6B1214"
                />
              </svg>
              <div className="no-order-text">
                <div className="saved-page-no-item-title">
                  Your Saved Items List Is Empty
                </div>
                <div className="save-page-no-item-subtitle">
                  Add all your favorites to the saved items
                </div>
                <div
                  style={{
                    color: "#6F6F6F",
                    fontSize: "12px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => shopNewIn()}
                >
                  Shop New In
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-order-div-container">
            <div className="no-order-div">
              <svg
                width={!isTabletOrMobile ? "89" : "40"}
                height={!isTabletOrMobile ? "77" : "38"}
                viewBox="0 0 89 77"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49.5304 76.9068H39.5212L0 37.2429V12.673L12.0086 0.621094H36.49L41.3141 5.47552L36.7609 10.0452L33.82 7.09366H14.6786L6.44928 15.3527V34.5632L42.1912 70.4342H46.8604L82.5507 34.6021V15.3915L74.3086 7.11955L55.1929 7.1325L25.2683 37.2558L20.7022 32.6862L52.51 0.672874L76.9786 0.646984L89 12.7248V37.2817L49.5304 76.9068Z"
                  fill="#6B1214"
                />
              </svg>
              <div className="no-order-text">
                <div className="saved-page-no-item-title">
                  Your Saved Items List Is Empty
                </div>
                <div className="save-page-no-item-subtitle">
                  You can save your favorite item here. Go to{" "}
                  <span
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={shopNewIn}
                  >
                    New in
                  </span>{" "}
                  to start shopping now. Sign in to sync your Saved Items across
                  all your devices.
                </div>
                <Button
                  className="save-page-no-item-saved-button"
                  onClick={() => setBuyerSignInAuthModalVisible(true)}
                >
                  Sign in
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <AuthModal
        authModalVisible={buyerSignInAuthModalVisible}
        setAuthModalVisible={setBuyerSignInAuthModalVisible}
        activePage="1"
        pageUserType="buyer"
      />

      {/* // ) : (
      //   <div className="saved-items-container">
      //     <div className="page-title">
      //       <img
      //         src="/images/back-arrow.png"
      //         alt=""
      //         className="back-icon"
      //         onClick={() => navigate(-1)}
      //       />
      //       <h3 className="txt-title">Saved Items</h3>
      //     </div>
      //     {savedItems.length > 0 ? (
      //       <div className="items-container">
      //         {savedItems.map((item) => (
      //           <div className="item-container">
      //             <img src={item.img1} alt="" className="item-img" />
      //             <h4 className="item-brand">{item.name}</h4>
      //             <h4 className="item-name">{item.brand}</h4>
      //             <Select className="size-select" placeholder="Select Size">
      //               <Option value={item.size}>{item.size}</Option>
      //             </Select>
      //             <Button
      //               className="btn-add-to-bag"
      //               icon={
      //                 <ShoppingOutlined
      //                   className="btn-add-icon"
      //                   style={{ float: "right" }}
      //                 />
      //               }
      //               type="primary"
      //             >
      //               Add to bag
      //             </Button>
      //           </div>
      //         ))}
      //       </div>
      //     ) : (
      //       <div className="no-order-div">
      //         <ExclamationCircleFilled
      //           style={{ fontSize: 22, color: "#fff" }}
      //         />
      //         <span className="no-order-text">You have no items saved</span>
      //       </div>
      //     )}
      //   </div>
      // )} */}

      <style jsx="true">
        {`
          .saved-items-container {
            background: rgb(240 240 240);
            margin: 0;
          }
          .saved-mobile-filter-container {
            font-size: 20px;
          }
          .page-details-container {
            // margin: 40px auto;
            margin: 0px auto;
            padding: 30px 0;
            width: 100%;
            text-align: center;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .page-header-title {
            font-family: DomaineSansText-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 39px;
            text-align: center;
          }
          .page-desc {
            font-family: DomaineSansText-Light;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
          .page-line {
            background: #c4c4c4;
            height: 1px;
            width: 70%;
            margin-top: 15px;
          }

          .guest-sync-container {
            margin: 30px 0;
            padding: 30px 50px;
            background: #fff;
          }

          .saved-items-list-container {
            margin: 0;
            padding: 40px 50px;
            background: #fff;
          }

          .sort-container {
            font-family: DomaineSansText-Regular;
            padding-left: 15px;
          }
          .saved-items-sort {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            width: 12%;
          }
          .saved-item-size {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #92959e;
          }
          .saved-items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
            margin-top: 30px;
          }
          .saved-item {
            width: 300px;
            height: 600px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 70px;
            position: relative;
          }
          .product-img {
            border: 1px solid #f4f4f4;
            cursor: pointer;
            width: 300px;
            height: 400px;
            object-fit: cover;
          }

          .icon-close {
            cursor: pointer;
            right: -3%;
            position: absolute;
            top: -2%;
            z-index: 100;
          }
          .saved-item-details {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 250px;
          }
          .saved-item-name {
            font-family: DomaineSansText-Regular;
            font-style: normal;
            font-weight: 900;
            font-size: 15px;
            line-height: 24px;
            color: #32353c;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
          }
          .saved-item-price {
            font-family: DomaineSansText-Light;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            margin-bottom: 0px;
          }
          .saved-item-select-option {
            font-family: DomaineSansText-Light;
            font-size: 12px;
            font-weight: 400;
            width: 96%;
            height: 24px;
          }
          .saved-item-select-option.ant-select-single:not(
              .ant-select-customize-input
            )
            .ant-select-selector {
            border: 0px;
            border-radius: 0;
            border-bottom: 0.1px solid #000;
            align-items: center;
            margin-top: 0px;
            padding: 0px;
          }
          .saved-item-select-option .ant-select-arrow {
            top: 70%;
          }
          .btn-add-item {
            background: #000;
            border-radius: 2px;
            color: #fff;
            font-family: DomaineSansText-Light;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            margin-top: 1rem;
            height: 48px;
            width: 100%;
            border: 0;
          }

          .no-order-div {
            background: white;
            padding: 80px;
            font-size: 16px;
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
            width: 70%;
          }
          .no-order-div-container {
            background: rgb(240 240 240);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 0;
          }
          .no-order-text {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .saved-page-no-item-title {
            font-size: 38px;
            margin-top: 14px;
          }

          .save-page-no-item-subtitle {
            width: 430px;
            margin: auto;
            font-size: 16px;
            margin-bottom: 24px;
            margin-top: 8px;
          }

          .save-page-no-item-saved-button {
            height: 48px;
            background: black;
            color: white;
            width: 300px;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .saved-item {
              margin-right: 0.6rem;
              margin-left: 0.6rem;
            }
          }

          @media (max-width: 640px) {
            .page-details-container {
              padding: 15px 0;
              width: 100%;
            }
            .page-header-title {
              font-size: 24px;
              // text-align: left;
              margin: 0;
            }
            .page-desc {
              font-size: 14px;
              text-align: left;
            }
            .page-line {
              background: #c4c4c4;
              height: 1px;
              width: 95%;
              margin-top: 10px;
            }
            .guest-sync-container {
              margin: 0 0;
              padding: 20px 16px;
              background: #fff;
            }
            .guest-sync-container p {
              font-size: 14px;
            }
            .saved-items-list-container {
              padding: 16px;
            }
            .saved-items {
              gap: 8px;
              justify-content: space-between;
              grid-template-columns: 1fr 1fr;
            }
            .saved-item {
              width: 185px;
              height: auto;
              margin-right: 0rem;
              margin-left: 0rem;
            }
            .no-order-div-container {
              padding: 0;
            }
            .no-order-div {
              width: 100%;
              height: 100%;
              // padding: 16px;
              margin: 0;
            }
            .no-order-text {
              width: 300px;
            }
            .saved-page-no-item-title {
              font-size: 18px;
              font-weight: 600;
            }
            .save-page-no-item-subtitle {
              width: 100%;
              font-size: 14px;
              // margin: 25px 0 30px;
            }
            .product-img {
              height: 220px;
              width: 100%;
            }
            .saved-item-details {
              height: auto;
              margin-top: 8px;
              gap: 8px;
            }
            .saved-item-name {
              height: 36px;
              display: block;
              display: -webkit-box;
              display: -moz-box;
              line-height: normal;
              font-size: 13px;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
            }
            .saved-item-select-option.ant-select-single:not(
                .ant-select-customize-input
              )
              .ant-select-selector {
              height: 32px !important;
              padding: 0;
            }
            .saved-item-select-option.ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              padding: 0 !important;
            }
            .btn-add-item {
              height: 40px;
              font-size: 13px;
            }
            .saved-item-price {
              font-size: 13px;
            }
            .icon-close {
              width: 24px;
              height: 24px;
              right: -6%;
            }
            .saved-mobile-filter-container {
              font-family: DomaineSansText-Light;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .saved-items-sort {
              width: 130px;
            }
            .sort-container {
              padding-left: 0;
            }
            // .sort-container
            //   .ant-select-single:not(.ant-select-customize-input)
            //   .ant-select-selector {
            //   margin-top: 0 !important;
            //   height: auto !important;
            // }
          }
        `}
      </style>
    </div>
  );
};

export default SavedItems;
