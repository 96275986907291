// import { useEffect } from "react";
// import { useMediaQuery } from "react-responsive";
import StaticPageTemplate from "./StaticPageTemplate";


const About = () => {

  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div>
      <StaticPageTemplate title={"About Us"}>
      <div className="static-content-container">
           <p>
              <b>Losode</b> is an ecommerce marketplace platform that connects
              fashion designers and fashion brands to customers across Sub -
              Saharan Africa making it easy for consumers to access quality
              clothing at affordable prices. We pride ourselves on our ability
              to offer the fashionable, yet affordable clothing, shoes, and
              accessories to both men and women. <br />
              <br />
              <b>'Losode'</b> is a Yoruba phrase that means{" "}
              <b>‘let’s go to town’</b> or <b>‘let’s go out’</b>. The Yoruba are
              described as a people of West Africa, living chiefly in the
              coastal regions of South Western Nigeria noted for their former
              city state and complex material culture, particularly as evidenced
              in their music, art and sculpture by the Collins dictionary, it is
              little wonder we identify strongly with them. As an organisation
              centred on developing the African fashion industry, we thought it
              best to identify with a tribe so diverse and grounded in its
              roots. Our style range encapsulates both ethnically African
              concepts and western styles to ensure robustness for all our
              customers.
              <br />
              <br /> Here at <b>Losode</b>, we work with a plethora of fashion
              brands to offer the highest quality pieces of clothing and fashion
              accessories, specifically targeted to the African market. Our
              style range encapsulates both ethnically African concepts and
              western styles to ensure robustness for all our customers.
              <br />
              <br />
              <span className="txt-description">
                At <b>Losode</b>, our mission is crafted around these five core
                goals:
              </span>
              <ul className="list-goals">
                <li className="goal">Empowering Entrepreneurs</li>
                <li className="goal">Smashing existing trade borders</li>
                <li className="goal">Doing Big Business</li>
                <li className="goal">Serving the industries we play in </li>
                <li className="goal">Boldly and courageously</li>
              </ul>
              <p className="txt-description">
                As a platform, we help entrepreneurs become more profitable and
                scale their fashion businesses.
              </p>
            </p>
            <div className="about-content">
              <h3 className="txt-sellers">For Fashion Lovers</h3>
              <p className="txt-description">
                Ever wondered if there was one place where you could satisfy
                your fashion cravings? Well… now there is, At Losode, we value
                you and we value your fashion needs.
              </p>
              <p className="txt-description">
                With offerings from a plethora of fashion brands globally, we
                have worked very hard to build a brand that you can trust. We
                give you access to a wide variety of fashions and accessories
                that are affordable. We have also put a lot into ensuring our
                business serves you excellently.
              </p>
              <h4 className="txt-promise">Our promise to you</h4>
              <ul className="list-promise">
                <li className="txt-description">
                  Access to a secure, reliable, and quick checkout process
                </li>
                <li className="txt-description">
                  Excellent customer support experience
                </li>
                <li className="txt-description">
                  Clear and easy-to-read exchange and returns policy Access to a
                  competitive marketplace
                </li>
                <li className="txt-description">
                  A wide variety of fashions, accessories, and shoes
                </li>
                <li className="txt-description">
                  Trusted and verified fashion brands{" "}
                </li>
                <li className="txt-description">
                  Quality measures that ensure you get what you bought
                </li>
                <li className="txt-description">Trusted logistic partners</li>
                <li className="txt-description">Fashion and style news</li>
                <li className="txt-description">Multiple payment options</li>
                <li className="txt-description">
                  Local Customer Experience Management
                </li>
                <li className="txt-description">
                  A personalized ‘User – Experience’ experience
                </li>
              </ul>
            </div>
            <div className="about-content">
              <h3 className="txt-sellers">For Fashion Brands</h3>
              <p className="txt-description">
                At Losode, we value partnerships. We actively work with fashion
                brands to serve some of the largest nations on the continent.
                With Brands from Nigeria, Ghana, South Africa, and customers all
                over the world, we are the perfect partner for your business.
              </p>
              <h4 className="txt-txt-description">Our promise to you</h4>
              <ul className="list-promise">
                <li className="txt-description">
                  Increase profitability for your business through exposure to
                  foreign markets
                </li>
                <li className="txt-description">
                  New value adding and revenue generating streams for your
                  business
                </li>
                <li className="txt-description">
                  Access to new and less congested markets
                </li>
                <li className="txt-description">
                  A partner that is solely focused on fashion and beauty
                </li>
                <li className="txt-description">
                  Access to large customer bases
                </li>
                <li className="txt-description">
                  Your own technology store front on Losode
                </li>
                <li className="txt-description">Low set-up costs</li>
                <li className="txt-description">
                  Unique features on Losode’s fashion and style magazine –'The
                  Verdict' and Losmedia
                </li>
                <li className="txt-description">
                  Participation in multiple capital markets by trading in
                  ‘local’ currency
                </li>
                <li className="txt-description">
                  Local Customer Experience Management
                </li>
                <li className="txt-description">Top – shelf advertising</li>
                <li className="txt-description">
                  Support with local and international logistic
                </li>
                <li className="txt-description">
                  Access to multiple payment options – local and international
                </li>
                <li className="txt-description">
                  Losode is registered in the United Kingdom
                </li>
              </ul>
            </div>
           </div>
      </StaticPageTemplate>
      <style jsx="true">{`
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        @media (max-width: 640px) {
          
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </div>
  );
};

export default About;
