import {Checkbox} from "antd"
import capitalizeStr from "../../utils/capitalizeStr";

const BrandDiv = ({products, checkBrand}) => {
  const sortedBrands = products?.sort((a, b) =>
    a.store_name.localeCompare(b.store_name, "en", { sensitivity: "base" })
  );

  return (
    <div className="brands-container">
      {sortedBrands.map((brand) => (
        <div className="brands-div" key={brand.seller_id}>
          <Checkbox
            style={{
              marginTop: 10,
              fontSize: 12,
              textTransform: "capitalize",
            }}
            onClick={() => checkBrand(brand.seller_id)}
          >
            {capitalizeStr(brand.store_name?.toLowerCase())}
            <span
              style={{ color: "#C4C4C4", marginLeft: 5 }}
            >{`(${brand.size})`}</span>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default BrandDiv;
