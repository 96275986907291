import { Modal, Button, Divider, Checkbox } from "antd";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { userActionCreators } from "../../redux/user";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import triggerNotification from "../../hooks/triggerNotification";

const MailingListModal = ({ visible, handleCancel, subscriberEmail }) => {
  const [selectedList, setSelectedList] = useState({
    customer_list: false,
    designer_list: false,
    general_list: false,
  });

  const dispatch = useDispatch();
  const { joinNewsLetter } = bindActionCreators(userActionCreators, dispatch);

  const handleCheck = (listType) => {
    setSelectedList((prevList) => ({
      ...prevList,
      [listType]: true,
    }));
  };

  const handleSubmit = async () => {
    // Handle form submission logic here
    const values = { ...selectedList, email: subscriberEmail };
    const res = await joinNewsLetter(values);
    if (res.status === "ok") {
      handleCancel(false);
      triggerNotification({
        type: "success",
        message: "You have successfully joined our mailing list",
      });
    }
    if (res.status === "not ok") {
      triggerNotification({
        type: "error",
        message: res.message,
      });
    }
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      centered
      footer={null}
      width={!isTabletOrMobile ? "850px" : "100%"}
      body={{ padding: `${!isTabletOrMobile ? 24 : 10}` }}
      maskClosable={false}
      className="mailing-list-modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div
          style={{ backgroundImage: "url(/images/mailing-list-img.png)" }}
          className="mailing-list-modal-image"
        />
        <div
          style={{ flex: "1" }}
          className="mailing-list-modal-text-container"
        >
          <div style={{ textAlign: "center" }}>
            <div className="modal-title">Join Our Mailing List</div>
            <div className="modal-desc">
              Sign up to get exclusive updates, new arrivals, and inside-only
              discounts
            </div>
            <div className="modal-desc">
              Join the list you are interested in
            </div>
          </div>
          <div style={{ marginTop: "16px" }}>
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <div>
                <div
                  style={{
                    fontFamily: "DomaineSansText-Regular",
                    textTransform: "capitalize",
                  }}
                >
                  Our customer mailing list
                </div>
                <div style={{ fontSize: 11 }}>
                  (For purchase discounts and fashion updates)
                </div>
              </div>
              <Checkbox
                checked={selectedList.customer_list}
                onChange={() => handleCheck("customer_list")}
              />
            </div>
            <Divider
              style={{ margin: "12px 0", borderTop: "1px solid #6f6f6f" }}
            />
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <div>
                <div
                  style={{
                    fontFamily: "DomaineSansText-Regular",
                    textTransform: "capitalize",
                  }}
                >
                  Our designer mailing list
                </div>
                <div style={{ fontSize: 11 }}>
                  (For our partner designers and brands, access to special
                  offers and business information)
                </div>
              </div>
              <Checkbox
                checked={selectedList.designer_list}
                onChange={() => handleCheck("designer_list")}
              />
            </div>
            <Divider
              style={{ margin: "12px 0", borderTop: "1px solid #6f6f6f" }}
            />
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <div>
                <div
                  style={{
                    fontFamily: "DomaineSansText-Regular",
                    textTransform: "capitalize",
                  }}
                >
                  Our general mailing list
                </div>
                <div style={{ fontSize: 11 }}>(For everyone)</div>
              </div>
              <Checkbox
                checked={selectedList.general_list}
                onChange={() => handleCheck("general_list")}
              />
            </div>
            <Divider
              style={{ margin: "12px 0", borderTop: "1px solid #6f6f6f" }}
            />
          </div>
          <Button
            className="btn-mailing-list-submit"
            type="primary"
            onClick={handleSubmit}
          >
            Subscribe
          </Button>
        </div>
      </div>

      <style>
        {`
            .mailing-list-modal{
                font-family:DomaineSansText-Light;
            }
            .mailing-list-modal .ant-modal-body{
                padding: 0;
                height: 100%;
            }
            .mailing-list-modal-image{
                width: 100%;
                height: 500px;
                flex: 1;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            .mailing-list-modal-text-container{
                padding: 24px 30px;
            }
            .mailing-list-modal .modal-title {
                font-size: 24px;
                font-family:DomaineSansText-Light;
                font-weight: 700;
                line-height:31.03px;
                margin-bottom: 5px;
                text-align:center;
              }
              .mailing-list-modal .modal-desc {
                font-size: 14px;
                font-weight: 400;
                font-family:DomaineSansText-Light;
                line-height:23.27px;
                text-align:center;
                margin: 4px auto 0;
              }
    
                .btn-mailing-list-submit {
                background: #000;
                font-size: 16px;
                font-family:DomaineSansText-Light;
                line-height: 20.69px;
                width: 100%;
                height: 48px;
                margin-top: 30px;
                }
        
                .btn-mailing-list-submit:hover {
                background: #fff;
                border: 1px solid #000;
                color: #000;
                }

                @media (max-width: 640px) {
                    .mailing-list-modal {
                        width: 100%;
                    }
                    .mailing-list-modal-image {
                        display: none;
                    }
                    .mailing-list-modal-text-container {
                        padding: 24px;
                    }
                }
         `}
      </style>
    </Modal>
  );
};

export default MailingListModal;
