import { Collapse } from "antd";

// const { Panel } = Collapse;

// import { useEffect } from "react";
import { Tabs } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const FAQ = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const navigate = useNavigate();

  const buyerFaqArray = [
    {
      section: "Buying on Losode",
      questions: [
        {
          question:
            "Do I need to register to make a purchase on your platform?",
          answer:
            "No, but Losode recommends that customers register an account with Losode.com. That way we can keep our customers informed about their orders as well as our frequent promotions and offers.",
        },
        {
          question: "What type of clothing can I purchase on Losode?",
          answer:
            "You can buy ready to wear or made to order clothing. Ready to wear items are typically in stock and will be packaged for shipment once your payment is received. Made to order clothing are made after your payment is received. There is a lead time for preparation and delivery. This will vary by designer and will be visible on the listing.",
        },
        {
          question: "How do I know if an item is made to order?",
          answer: "This will be visible on the listing.",
        },
        {
          question:
            "Do I need to submit my measurements for made to order items?",
          answer:
            "Our designers provide a standard size guide for made to order clothing. What this means is that the designer begins to prepare your item in line with their size guide only after we have received payment from you. You need to select the sizing that best fits you, and the designer will then make the item to order.",
        },
        {
          question: "Do you have special discounts or promotions?",
          answer: (
            <div>
              Losode frequently offers promotions and discounts. Some of our
              promotions are exclusive to our email subscribers and social media
              followers. Please{" "}
              <span className="faq-emphasized">join our mailing list</span>,
              follow us on{" "}
              <a
                href="https://www.instagram.com/thisislosode/"
                className="faq-emphasized"
              >
                Instagram
              </a>
              ,{" "}
              <a
                href="https://twitter.com/thisislosode"
                className="faq-emphasized"
              >
                X(Twitter)
              </a>
              , and{" "}
              <a
                href="https://facebook.com/losode1/"
                className="faq-emphasized"
              >
                Facebook
              </a>
              , and <span className="faq-emphasized">register</span> an account
              with Losode to stay up to date with Losode news, events, and
              promotions.
            </div>
          ),
        },
        {
          question:
            "What payment options are available to me to make a purchase?",
          answer:
            "There are a range of payment options available to customers on the Losode platform, depending on your location. These include PayPal, Flutterwave, and Paystack. Customers are also able to pay directly to our bank accounts via Losode Pay.",
        },
        {
          question: "Can I order on someone else’s behalf?",
          answer:
            "Yes, you can. When ordering on someone else’s behalf, make sure to provide accurate sizing for items selected and enter their address at checkout to ensure it is delivered to them.",
        },

        {
          question: "Can I make a purchase outside of Africa?",
          answer:
            "Yes, customers outside of Africa can purchase items from our stores and make payments through the payment options listed above.",
        },
        {
          question: "Can I pay on delivery?",
          answer: "Losode does not currently have this feature available.",
        },
        {
          question: "Does Losode store my payment or credit card details?",
          answer:
            "Losode does not have access to see or store your payment or credit card details.",
        },
      ],
    },
    {
      section: "Managing my order",
      questions: [
        {
          question:
            "Can I change my delivery address after I have placed an order?",
          answer:
            "Delivery addresses can only be changed before the order is completed. You would need to arrange to collect your item from the delivery address on your order.",
        },
        {
          question: "Can I change my order after I have placed an order?",
          answer: (
            <div>
              Losode begins processing your order immediately it is placed, as
              such you will not be able to change your order. If you have any
              issues with your order, contact us at{" "}
              <a href="mailto:hello@losode.com" className="faq-emphasized">
                hello@losode.com
              </a>
              , quoting your order number.
            </div>
          ),
        },
        {
          question: "How do I check on the status of my order?",
          answer: (
            <div>
              If you are a registered user, sign in to your account at{" "}
              <a href="/" className="faq-emphasized">
                losode.com
              </a>{" "}
              and go to the{" "}
              <a href="/orders" className="faq-emphasized">
                My Orders
              </a>{" "}
              page in your account dashboard. There you will see a list of your
              orders with the status of each order. If you checked out as a
              guest, you will have to email Losode at{" "}
              <a href="mailto:hello@losode.com" className="faq-emphasized">
                hello@losode.com
              </a>
              , quoting your order number. We aim to respond to all queries
              within 5 working days.
            </div>
          ),
        },
        {
          question: "What happens if my order does not go through?",
          answer: (
            <div>
              Please check that you have completed all relevant fields
              correctly, including your telephone number with country and city
              code, your email address, and your delivery address. For any
              technical difficulties, email us at{" "}
              <a href="mailto:hello@losode.com" className="faq-emphasized">
                hello@losode.com
              </a>
              .
            </div>
          ),
        },
        {
          question: "How do I know my order is complete?",
          answer:
            "Please review the order received against the order you placed by reviewing your order history.",
        },
        {
          question: "What should I do if I have received incorrect products?",
          answer: (
            <div>
              Please contact our customer service team at{" "}
              <a href="mailto:hello@losode.com" className="faq-emphasized">
                hello@losode.com
              </a>
              , quoting your order number, or{" "}
              <span className="faq-emphasized">sign in</span> to your account
              and raise a request from your account dashboard.
            </div>
          ),
        },
        {
          question: "What do I do if I have received extra products?",
          answer: (
            <div>
              Please contact our customer service team at{" "}
              <a href="mailto:hello@losode.com" className="faq-emphasized">
                hello@losode.com
              </a>
              , quoting your order number, or{" "}
              <span className="faq-emphasized">sign in</span> to your account
              and raise a request from your account dashboard.
            </div>
          ),
        },
        {
          question: "How do I track my order?",
          answer: (
            <div>
              Once you have placed an order, you should receive a tracking
              notification to your mobile phone or email address, where one is
              available, from your delivery provider. You should also be able to
              view it against your order on your account dashboard. If you
              cannot find your tracking details, please contact our customer
              service team at{" "}
              <a href="mailto:hello@losode.com" className="faq-emphasized">
                hello@losode.com
              </a>
              , quoting your order number, or{" "}
              <span className="faq-emphasized">sign in</span> to your account
              and raise a request from your account dashboard.
            </div>
          ),
        },
      ],
    },
    {
      section: "Requesting a Return",
      questions: [
        {
          question: "What is your returns policy?",
          answer: (
            <div>
              <div style={{ marginBottom: "8px" }}>
                If you are not satisfied with your purchase for any reason,
                raise a request within 48 hours of delivery. Losode will
                actively work with you and the designer to achieve a
                satisfactory outcome by either issuing a refund or initiating an
                exchange once the product(s) are returned in their original
                package and in their original, unworn condition, with all labels
                and tags intact.
              </div>
              <div style={{ marginBottom: "8px" }}>
                For hygiene reasons, some products cannot be returned; these
                include personal products such as jewellery, underwear,
                swimwear, and skincare products.
              </div>
              <div>
                Please see our{" "}
                <a href="/returns" className="faq-emphasized">
                  returns policy and process
                </a>{" "}
                for further information. Contact Losode at{" "}
                <a href="mailto:hello@losode.com" className="faq-emphasized">
                  hello@losode.com
                </a>{" "}
                or via your account{" "}
                <a href="/account" className="faq-emphasized">
                  dashboard
                </a>{" "}
                if you have any queries.
              </div>
            </div>
          ),
        },
        {
          question: "How do I request a refund?",
          answer: (
            <div>
              <div style={{ marginBottom: "8px" }}>
                Go to your account dashboard within 48 hours of receiving your
                order and select the relevant order from your order history. If
                the product is eligible for a refund, this will be visible
                against the order. You should complete the form and provide the
                reason for your request. Once you have submitted your request,
                you need to return the product within 5 working days at your own
                cost.{" "}
              </div>
              <div style={{ marginBottom: "8px" }}>
                If you would like a refund for products purchased but you cannot
                return them to Losode for any reason, then Losode will not be
                able to process a refund for those products except at our
                discretion.{" "}
              </div>
              <div>
                Please see our{" "}
                <a href="/returns" className="faq-emphasized">
                  returns policy and process
                </a>{" "}
                for further information.
              </div>
            </div>
          ),
        },
        {
          question: "How do I request an exchange?",
          answer: (
            <div>
              <div style={{ marginBottom: "8px" }}>
                Go to your account dashboard within 48 hours of receiving your
                order and select the relevant order from your order history. If
                the product is eligible for a refund, this will be visible
                against the order. You should complete the form and provide the
                reason for your request. Once you have submitted your request,
                you need to return the product within 5 working days at your own
                cost.{" "}
              </div>
              <div style={{ marginBottom: "8px" }}>
                Some designers may not allow you to return the product but would
                be happy for you to exchange your product for a different size,
                colour, or style of the same or higher value.
              </div>
              <div>
                Please see our{" "}
                <a href="/returns" className="faq-emphasized">
                  returns policy and process
                </a>{" "}
                for further information.
              </div>
            </div>
          ),
        },
        {
          question: "How do I prepare my products for return?",
          answer: (
            <div>
              <div style={{ marginBottom: "8px" }}>
                It is important that the product(s) are returned in their
                original package and that they are in their original, unworn
                condition, with all labels and tags intact.
              </div>
              <div style={{ marginBottom: "8px" }}>
                Losode will provide you with access to download and print the
                relevant return labels along with instructions on how to package
                your products to be returned to the designer for your refund or
                exchange.
              </div>
              <div style={{ marginBottom: "8px" }}>
                The products are your responsibility until they reach the
                designer from whom you purchased them. Please ensure they are
                properly packed and cannot get damaged on their journey back to
                the designer.{" "}
              </div>
              <div>
                You will be responsible for the cost of returning any
                product(s). Please use a postal service that insures you for the
                value of the products you are returning. Losode would advise
                that you send all products by recorded delivery to ensure that
                they reach the designer and that you retain postage evidence.
              </div>
            </div>
          ),
        },
        {
          question: "What are your returns processing timescales?",
          answer: (
            <div>
              <div style={{ marginBottom: "8px" }}>
                Losode can only process the relevant refund, exchange, or
                amendment for products that have been returned to the designer
                and confirmed by the designer as received. Once the designer
                confirms the receipt of the product, and that it is in its
                original condition, all refunds and exchanges will be issued
                within 10 working days of confirmation by the designer.
              </div>
              <div>
                If you have any issues with your returns, please contact Losode
                at{" "}
                <a href="mailto:hello@losode.com" className="faq-emphasized">
                  hello@losode.com
                </a>
                .
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  const losodeFaqArray = [
    {
      question: "Who are our designers? ",
      answer:
        "These mainly comprise of independent fashion designers across Africa as well as international fashion designers inspired by Africa. Losode also provides locally established African designers with a scalable platform to expand their global reach. ",
    },
    {
      question: "Does Losode have a physical store? ",
      answer: (
        <>
          <div>
            Our model is to provide easy and convenient access to fashion
            designers by enabling our customers across the world to shop
            conveniently online and have products delivered right to their
            door.  On occasion, our designers may participate in trade events at
            select locations. 
            <span className="faq-emphasized">Join our mailing list</span> today
            to stay informed on Losode news and events. 
          </div>
        </>
      ),
    },
    {
      question: "What are Losode’s hours of operation? ",
      answer:
        "Our website operates 24 hours a day, 7 days a week.  Our customer service teams are available between 0800 to 1800 West African Time.",
    },
    {
      question: "How do I contact Losode? ",
      answer: (
        <>
          <div>
            Contact Losode at{" "}
            <a href="mailto:hello@losode.com" className="faq-emphasized">
              hello@losode.com{" "}
            </a>
          </div>
        </>
      ),
    },
  ];

  const sellerFaqArray = [
    {
      section: "Becoming a Losode Designer",
      questions: [
        {
          question: "How do I become a Losode designer?",
          answer: (
            <div>
              You can join Losode as a designer by completing an application form
              at{" "}
              <a href="/sell" className="faq-emphasized">
                losode.com/sell
              </a>
              . Losode will guide you through our onboarding process, support
              you in setting up your store, performing photoshoots, and listing
              your products. Please follow this link to{" "}
              <span
                className="faq-emphasized"
                onClick={() => navigate("/sell")}
              >
                join Losode
              </span>{" "}
              .
            </div>
          ),
        },
      ],
    },
    {
      section: "Selling on Losode",
      questions: [
        {
          question: "Who can sell on Losode?",
          answer:
            "Losode welcomes growing and established independent fashion designers and individuals with a flair for fashion, beauty, and skincare.",
        },
        {
          question: "What can I sell on Losode?",
          answer:
            "You can sell clothing, footwear, accessories, and skincare for men, women, and children.",
        },
        {
          question: "Does Losode operate a wholesale model?",
          answer: (
            <div>
              Losode does not currently provide a wholesale service. If you are
              interested in discussing opportunities with Losode, please contact
              us at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              .
            </div>
          ),
        },
      ],
    },
    {
      section: "Fees and Subscriptions",
      questions: [
        {
          question: "What fees do I pay to Losode?",
          answer:
            "It is free to join Losode as a designer. Losode applies a percentage commission to the list price of the item.",
        },
        {
          question: "Do I need to pay a subscription fee to Losode?",
          answer: "No, Losode does not charge a subscription fee.",
        },
      ],
    },
    {
      section: "Setting up a Store",
      questions: [
        {
          question: "What information do I need to set up my store?",
          answer:
            "Our main requirement is that you have an established brand presence on social media and have products ready to list and sell on the platform. Once you have joined Losode as a designer, we will guide you through our onboarding process and all our onboarding requirements.",
        },
        {
          question:
            "What support do you provide to register my store and list my products?",
          answer: (
            <div>
              Losode has designed a seamless onboarding process. Our dedicated
              seller team is on hand to provide additional support. Please raise
              a request using this{" "}
              <span
                className="faq-emphasized"
                onClick={() => navigate("/contact-us")}
              >
                contact form
              </span>{" "}
              or send an email to{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              .
            </div>
          ),
        },
        {
          question: "How long does it take to set up my store?",
          answer:
            "Once your application is approved, if you have your photographs ready and can commit to listing at least twelve products in your first week, Losode expects you to be ready to sell in less than three days.",
        },
      ],
    },
    {
      section: "Photoshoots",
      questions: [
        {
          question: "Do you provide support for Photoshoots?",
          answer:
            "Losode provides access to Losode approved photographers who are familiar with our brand and listing guidelines and with whom Losode has negotiated preferential rates.",
        },
        {
          question: "How do I book a photoshoot?",
          answer: (
            <div>
              If you are already a Losode designer, follow the link on your designer
              dashboard to{" "}
              <span className="faq-emphasized">request a photoshoot</span>,
              otherwise, please{" "}
              <span
                className="faq-emphasized"
                onClick={() => navigate("/sell")}
              >
                join Losode
              </span>{" "}
              as a designer.
            </div>
          ),
        },
      ],
    },
  ];

  const sellerHelpFaqArray = [
    {
      section: "Setting up my store and listing items",
      questions: [
        {
          question: "How do I list an item?",
          answer:
            "We have designed an item listing wizard which will guide you through the process of listing an item as well as our listing criteria.",
        },
        {
          question: "How long does it take to list an item?",
          answer:
            "If you have your photographs and item descriptions and weights ready, we expect it to take between three and five minutes to list an item.",
        },
        {
          question: "What support will you provide to help me list an item?",
          answer:
            "We have designed several support videos to enhance your success as a Losode designer.",
        },
        {
          question: "What happens once my item is listed?",
          answer:
            "Your listing will be submitted to our customer operations team for quality review. Once this is complete, we will either approve the listing or ask you to make changes to the listing before it can be approved.",
        },
        {
          question:
            "I have listed my items, but my store is not yet visible on the platform.",
          answer: (
            <div>
              We require a minimum number of item listings in each store to
              publish your store. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              , and we will be happy to help you.
            </div>
          ),
        },
      ],
    },
    {
      section: "Receiving Orders",
      questions: [
        {
          question: "Who can make purchases from my store?",
          answer:
            "Customers within and outside of Africa can purchase items from your store and make payments through our 3rd party payment providers. Payouts will be made to you in your local currency.",
        },
        {
          question: "How do I get notified that I have received an order?",
          answer: (
            <div>
              You will be notified by email and SMS immediately an order is
              placed on your store. You will receive confirmation of the likely
              collection timing. Please note delivery timescales and cut-off
              times in our <span className="faq-emphasized">delivery FAQs</span>
              .
            </div>
          ),
        },
        {
          question:
            "Can I reject an order? What if I no longer have the item in stock?",
          answer:
            "You can reject an order but there may be a penalty as we operate on the basis that if an item is in your store, it is ready and available to ship. If an item is no longer available, you must delist it immediately. We expect you to keep your store up to date daily and to delist items that are no longer in stock as soon as possible. We are keen to work with designers to minimize disappointing customers by canceling orders.",
        },
        {
          question: "How do customers pay?",
          answer: (
            <div>
              Losode collects payments from customers on behalf of our designers and
              issue payouts to our designers monthly in line with the designers’{" "}
              <a href="/returns" className="faq-emphasized">
                returns policy
              </a>
              . Customers can pay using PayPal, Flutterwave, Paystack, or Losode
              Pay.
            </div>
          ),
        },
      ],
    },
    {
      section: "Preparing and Shipping Orders",
      questions: [
        {
          question: "How do I package an order for shipment?",
          answer:
            "Customers within and outside of Africa can purchase items from your store and make payments through our 3rd party payment providers. Payouts to you will be made to you in your local currency.",
        },
        {
          question: "Can I use my own packaging?",
          answer: (
            <div>
              We expect all our designers to use Losode packaging when sending
              orders to our customers. This packaging is available for a nominal
              fee on Losode.com. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              , and we will be happy to help you.
            </div>
          ),
        },
        {
          question:
            "Can I contact the courier directly if there will be a delay in packaging the order?",
          answer: (
            <div>
              Please contact us immediately at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              , and we will be happy to help you.
            </div>
          ),
        },
        {
          question: "When will the order be collected by the courier?",
          answer: (
            <div>
              In most instances, the courier will contact you prior to the
              collection of the parcel. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              , and we will be happy to help you.
            </div>
          ),
        },
        {
          question: "How does Losode handle shipping and delivery?",
          answer:
            "Losode provides access to delivery companies who ship locally within Nigeria and to other parts of the world. Losode is usually able to ship at competitive rates due to these partnerships.",
        },
        {
          question: "Can I deliver directly to customers?",
          answer: "No, Losode does not currently offer this service.",
        },
      ],
    },
    {
      section: "Handling Returns",
      questions: [
        {
          question: "How does Losode handle returns (refunds and exchanges)?",
          answer: (
            <div>
              <div style={{ marginBottom: "8px" }}>
                For each listing, you will be asked to confirm your returns
                policy and specify whether you offer a refund or exchange.
                Losode presents these options to the customer and will process any
                requests from the customer in line with your policy. If as a seller
                you do not have a policy in existence, then our refunds and
                exchanges policy will apply.
              </div>
              <div>
                You can find our returns policy{" "}
                <a href="/returns" className="faq-emphasized">
                  here
                </a>
                .
              </div>
            </div>
          ),
        },
      ],
    },
    {
      section: "My payouts",
      questions: [
        {
          question: "What are my payout options?",
          answer:
            "Payouts for purchases from your store are made directly to your bank account. They will be made in your local currency.",
        },
        {
          question: "How often does Losode issue payouts to designers?",
          answer:
            "Losode pays designers a month in arrears and subject to the designer's own refund and exchange policy. This is to ensure that customers are satisfied with their orders and have had a chance to make changes to their orders within statutory limits and listing conditions.",
        },
        {
          question:
            "How long does it take to receive payouts into my bank account?",
          answer: (
            <div>
              Losode will make payments to you on your payout due dates. Bank
              clearing times can vary from bank to bank. If you have not
              received your payout 72 hours after your payout date, please
              contact your bank in the first instance and then send an email to{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              .
            </div>
          ),
        },
        {
          question:
            "How do I know exactly when I will receive my payout and the amount?",
          answer:
            "Your next payment date can be found in “My Payments” on your account dashboard.",
        },
        {
          question: "What bank account will you use for my payout?",
          answer:
            "Payments will be made directly to the bank account provided by you, and verified by Losode, when you set up your Losode account.",
        },
        {
          question: "How do I change my bank details?",
          answer: (
            <div>
              If you want to change your bank account, please contact Losode at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>{" "}
              and we will provide you with details on how to do so.
            </div>
          ),
        },
        {
          question: "How does Losode handle my bank details?",
          answer:
            "Your bank details are stored in a secure part of our platform and access is restricted. When you provide your bank details at set up, you will undergo a verification process. After verification, you will be allowed to view but not change your bank account details on the platform. Any subsequent requests by you to change to your bank details will need to follow our stringent re-verification process to prevent unauthorized changes to your bank details.",
        },
        {
          question:
            "I have not received the payout for recent purchases from my store, what do I do?",
          answer: (
            <div>
              Losode pays designers a month in arrears and in line with their
              returns policy. Ensure you have checked your transaction history
              and payment due date before you send an email. For payment
              queries, disputes, or concerns, please send an email to{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              .
            </div>
          ),
        },
        {
          question:
            "Why does my payout show ‘paid’ but no funds have been deposited?",
          answer: (
            <div>
              Please ensure you have checked your transaction history and payout
              due date on your account page, and if the payout is overdue,
              please send an email to{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              .
            </div>
          ),
        },
        {
          question: "What should I do if I have a query or dispute?",
          answer: (
            <div>
              It is our important that our designers are paid as quickly as
              possible. Disputes are handled with the utmost level of
              seriousness. Please send payment queries, disputes, or concerns to{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              .
            </div>
          ),
        },
      ],
    },
    {
      section: "Managing my account",
      questions: [
        {
          question: "I am going on holiday, how do I set my store to vacation?",
          answer:
            "To set your store on vacation, go to your dashboard, click on 'Store Settings' in the sidebar, choose the 'Vacation' tab, provide a reason, and toggle the switch. You'll receive a notification for the action.",
        },
        {
          question: "How do I close my store?",
          answer: (
            <div>
              We will be sad to see you go. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>
              , and we will guide you through the process.
            </div>
          ),
        },
        {
          question:
            "Can I share account access with my employees to help with customer requests?",
          answer:
            "Losode does not currently offer multi-account access. You are required to keep your account information secure as Losode cannot accept responsibility for the unauthorized use of your account by someone to whom you have provided your account details.",
        },
        {
          question: "How do I change my bank details?",
          answer: (
            <div>
              If you want to change your bank account, please contact Losode at{" "}
              <a href="mailto:designers@losode.com" className="faq-emphasized">
                designers@losode.com
              </a>{" "}
              and we will provide you with details on how to do so.
            </div>
          ),
        },
      ],
    },
  ];

  const deliveryFaqArray = [
    {
      question: "What delivery options do you offer?",
      answer:
        "Losode offers same day, next day, and standard delivery options depending on your location. Losode will always present you with the cheapest option for the weight and distance relevant to your order. The timelines above relate to working days (Monday to Friday) and do not include public holidays. The delivery timelines vary depending on the delivery option chosen.",
    },
    {
      question: "What does delivery cost?",
      answer:
        "Your shipping cost is based on the delivery option you select i.e., same day, next day, or standard, the number of designers you shop from, the number of items that you purchase, the distance between your delivery address and the designer’s store, and the total weight of all the items. The total shipping cost is displayed during the checkout process before you proceed to payment.",
    },
    {
      question:
        "Why do I need to pay to have products delivered from different designers?",
      answer:
        "As a marketplace, Losode provides customers with access to designers; therefore, Losode does not carry any of its own stock. As all shipping is direct from the designers to the customers, if you place multiple items from different stores into your basket, you will pay for shipping from each designer.",
    },
    {
      question: "What are your delivery destinations?",
      answer:
        "Losode currently delivers to all states in Nigeria, select African countries, the USA, and the UK.",
    },
    {
      question: "When will my order be shipped?",
      answer:
        "Order shipment process begins immediately after payment has been confirmed, and the delivery times vary depending on the delivery type you choose.",
    },
    {
      question: "What shipping carriers do you use?",
      answer:
        "Losode has partnered with multiple logistics companies to ensure Losode provides a seamless experience for you. Our algorithm ensures the most suitable partner is picked based on price, user experience, and delivery times.",
    },
  ];

  const location = useLocation();

  const [FAQsTabValue] = useState(localStorage.getItem("FAQsTab"));

  useEffect(() => {
    localStorage.removeItem("FAQsTab");
  }, []);

  return (
    <div>
      <div className="about-container">
        <div className="title-div-about">
          <div className="text-container">
            <h1 className="lg-txt">Questions</h1>
          </div>
        </div>
        <Tabs
          defaultActiveKey={
            location?.state?.activeFAQ === "delivery"
              ? "4"
              : FAQsTabValue === "seller"
              ? "1"
              : "5"
          }
          className="tabs"
          tabBarGutter={!isTabletOrMobile ? 50 : 15}
        >
          <TabPane className="tab" tab="About Losode" key="5">
            <div className="about-content">
              <div className="seller-faq-section" id="faqs">
                <div className="seller-faq-container">
                  {/* <div className="seller-faq-bold-title">
                    Frequently Asked Questions
                  </div> */}
                  {losodeFaqArray.map((question, idx) => (
                    <div key={idx}>
                      <Collapse
                        className="collapse-container"
                        ghost
                        expandIconPosition="right"
                      >
                        <Collapse.Panel
                          className="panel"
                          header={question.question}
                          // key={question.id}
                        >
                          <p
                            className="txt-faq-ans"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {question.answer}
                          </p>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane className="tab" tab="Designer FAQs" key="1">
            <div className="about-content">
              <div className="seller-faq-section" id="faqs">
                <div className="seller-faq-container">
                  {/* <div className="seller-faq-bold-title">
                    Frequently Asked Questions
                  </div> */}
                  {sellerFaqArray.map((item, idx) => (
                    <div key={idx}>
                      <Collapse
                        className="section-collapse-container"
                        ghost
                        expandIconPosition="right"
                      >
                        <Collapse.Panel
                          className="section-panel"
                          header={
                            <div className="faq-section-title">
                              {item?.section}
                            </div>
                          }
                          // key={question.id}
                        >
                          {item?.questions?.map((questionObject) => (
                            <Collapse
                              className="collapse-container"
                              ghost
                              expandIconPosition="right"
                            >
                              <Collapse.Panel
                                className="panel"
                                header={questionObject?.question}
                                // key={question.id}
                              >
                                <p className="txt-faq-ans">
                                  {questionObject?.answer}
                                </p>
                              </Collapse.Panel>
                            </Collapse>
                          ))}
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane className="tab" tab="Customers FAQs" key="2">
            <div className="about-content">
              <div className="seller-faq-section" id="faqs">
                <div className="seller-faq-container">
                  {/* <div className="seller-faq-bold-title">
                    Frequently Asked Questions
                  </div> */}
                  {buyerFaqArray.map((item, idx) => (
                    <div key={idx}>
                      <Collapse
                        className="section-collapse-container"
                        ghost
                        expandIconPosition="right"
                      >
                        <Collapse.Panel
                          className="section-panel"
                          header={
                            <div className="faq-section-title">
                              {item?.section}
                            </div>
                          }
                          // key={question.id}
                        >
                          {item?.questions?.map((questionObject) => (
                            <Collapse
                              className="collapse-container"
                              ghost
                              expandIconPosition="right"
                            >
                              <Collapse.Panel
                                className="panel"
                                header={questionObject?.question}
                                // key={question.id}
                              >
                                <p className="txt-faq-ans">
                                  {questionObject?.answer}
                                </p>
                              </Collapse.Panel>
                            </Collapse>
                          ))}
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane className="tab" tab="Delivery FAQs" key="4">
            <div className="about-content">
              <div className="seller-faq-section" id="faqs">
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      marginBottom: "40px",
                      width: "100%",
                      overflowX: "auto",
                    }}
                  >
                    <div className="seller-faq-bold-title">
                      Delivery Timelines
                    </div>
                    <table className="faq-delivery-table">
                      <tr>
                        <th></th>
                        <th>Same Day Delivery Cut Off</th>
                        <th>Express / Next Day Delivery Cut Off</th>
                        <th>Standard Delivery Cut Off</th>
                      </tr>
                      <tr>
                        <td>
                          Intra-city
                          <br />
                          (e.g., within Lagos)
                        </td>
                        <td>
                          Order cut-off: <strong>2 pm</strong> <br />
                          Delivery:{" "}
                          <strong style={{ color: "#FF0000" }}>
                            Before 10pm
                          </strong>
                        </td>
                        <td>
                          Order cut-off: <strong>2 pm</strong>
                          <br />
                          Delivery: <strong>Within 24 hours</strong>
                        </td>
                        <td>
                          Order cut-off: <strong>2 pm</strong>
                          <br />
                          Delivery: <strong>3–5 working days</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Inter-city
                          <br />
                          (e.g., Lagos to Benin)
                        </td>
                        <td></td>
                        <td>
                          Order cut-off: <strong>3 pm</strong>
                          <br />
                          Delivery: <strong>3–5 working days</strong>
                        </td>
                        <td>
                          Order cut-off: <strong>midnight</strong>
                          <br />
                          Delivery: <strong>6 working days</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>International Deliveries</td>
                        <td></td>
                        <td>
                          Order cut-off: <strong>3 pm</strong>
                          <br />
                          Delivery: <strong>3 - 6 working days</strong>
                        </td>
                        <td>
                          Order cut-off: <strong>midnight</strong>
                          <br />
                          Delivery: <strong>5 - 11 working days</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Note: </td>
                        <td
                          className="faq-table-note-td"
                          style={{ textAlign: "left", verticalAlign: "top" }}
                        >
                          This option is only available in a limited number of
                          cities, and Losode continues to work with our delivery
                          partners to expand the options available to our
                          customers. 
                        </td>
                        <td
                          className="faq-table-note-td"
                          style={{ textAlign: "left", verticalAlign: "top" }}
                        >
                          <div style={{ marginBottom: "8px" }}>
                            Express Next Day: only available in Nigeria
                          </div>
                          <div>
                            Express International: available mainly to USA and
                            UK customers,  
                          </div>
                        </td>
                        <td
                          className="faq-table-note-td"
                          style={{ textAlign: "left", verticalAlign: "top" }}
                        >
                          Losode provides you with a specified delivery date
                          which is typically between 3 to 11 days of your order
                          date subject to your location. 
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="seller-faq-section">
                    <div className="seller-faq-container">
                      <div className="seller-faq-bold-title">
                        Frequently Asked Questions
                      </div>
                      {deliveryFaqArray.map((question, idx) => (
                        <div key={idx}>
                          <Collapse
                            className="collapse-container"
                            ghost
                            expandIconPosition="right"
                          >
                            <Collapse.Panel
                              className="panel"
                              header={question.question}
                              // key={question.id}
                            >
                              <p
                                className="txt-faq-ans"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {question.answer}
                              </p>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane className="tab" tab="Designer Help" key="3">
            <div className="about-content">
              <div className="seller-faq-section" id="faqs">
                <div className="seller-faq-container">
                  {/* <div className="seller-faq-bold-title">
                    Frequently Asked Questions
                  </div> */}
                  {sellerHelpFaqArray.map((item, idx) => (
                    <div key={idx}>
                      <Collapse
                        className="section-collapse-container"
                        ghost
                        expandIconPosition="right"
                      >
                        <Collapse.Panel
                          className="section-panel"
                          header={
                            <div className="faq-section-title">
                              {item?.section}
                            </div>
                          }
                          // key={question.id}
                        >
                          {item?.questions?.map((questionObject) => (
                            <Collapse
                              className="collapse-container"
                              ghost
                              expandIconPosition="right"
                            >
                              <Collapse.Panel
                                className="panel"
                                header={questionObject?.question}
                                // key={question.id}
                              >
                                <p className="txt-faq-ans">
                                  {questionObject?.answer}
                                </p>
                              </Collapse.Panel>
                            </Collapse>
                          ))}
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <style jsx="true">{`
        .about-container {
          background: #f4f4f4;
          overflow-x: scroll;
        }
        .title-div-about {
          background-image: url("/images/static-header-image.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 250px;
          position: relative;
        }

        .text-container {
          padding: 70px 60px;
          z-index: 1;
          display: flex;
          height: 100%;
          align-items: center;
        }
        .sm-txt {
          color: #fff;
          font: normal normal 24px/28px DomaineSansText-Light;
        }
        .lg-txt {
          color: #fff;
          font-family: "DomaineSansText-Regular";
          font-size: 48px;
          height: fit-content;
          margin-bottom: 0;
        }
        .tabs {
          background: #f2f2f261;
          padding: 16px 60px;
        }
        .tab {
        }
        .ant-tabs-tab-btn {
          font: normal normal 18px/146.16% DomaineSansText-Light;
          color: #677189;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #000;
          font-weight: 500;
        }

        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
        .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
          background: #000;
          width: 50px;
        }
        .about-content {
          font-size: 16px;
          line-height: 30px;
          font-family: "DomaineSansText-Light";
          padding-bottom: 40px;
        }
        .about-content li {
          padding-top: 12px;
        }
        .txt-description {
        }
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          border-top: 1px solid #c4c4c4;
          border-bottom: 1px solid #c4c4c4;
          padding: 16px;
          margin: 16px auto;
        }
        .panel {
          font-family: DomaineSansText-Light;
          font-size: 16px;
          font-weight: bold;
          // width: 1064px;
          width: 100%;
        }
        .section-panel {
          font-family: DomaineSansText-Light;
          font-weight: bold;
          // width: 1120px;
          width: 100%;
        }
        .section-collapse-container {
          border-top: 1px solid #c4c4c4;
          border-bottom: 1px solid #c4c4c4;
          padding: 16px;
          // margin: 16px auto;
          width: 100%;
        }
        .ant-collapse
          > .ant-collapse-item
          > .ant-collapse-header
          .ant-collapse-arrow {
          // top: 15px;
          font-size: 16px;
          right: 0px;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }

        .seller-faq-bold-title {
          font-size: 24px;
          text-align: center;
          margin-bottom: 8px;
          font-weight: bold;
          margin-bottom: 32px;
        }
        .seller-faq-section {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 24px;
          width: 100%;
        }
        .seller-faq-container {
          width: 100%;
        }
        .seller-faq-item {
          min-height: 154px;
          border: 1px solid #c4c4c4;
          padding: 24px 56px;
          margin-bottom: 16px;
        }

        .seller-faq-header {
          display: flex;
          justify-content: space-between;
          font-size: 24px;
          font-weight: 700;
        }
        .seller-faq-answer {
          font-size: 18px;
          max-width: 950px;
          margin-top: 24px;
          color: #6f6f6f;
        }

        .txt-faq-ans {
          font: normal normal 16px DomaineSansText-Light;
          text-align: left;
          line-height: inherit;
        }

        .ant-collapse
          > .ant-collapse-item
          > .ant-collapse-header
          .ant-collapse-arrow {
          padding: 20px 16px !important;
        }

        .process-item-link {
          color: #800000;
        }

        .logistics-figure-section {
          display: flex;
          gap: 24px;
          line-height: normal;
          font-size: 14px;
          margin-bottom: 24px;
        }
        .logistics-figure-section-left {
          display: flex;
          gap: 24px;
          flex-direction: column;
          width: 70%;
        }
        .logistics-column-container {
          display: flex;
          gap: 24px;
        }

        .logistics-box {
          width: 30%;
          border: 1px solid;
          padding: 16px;
          line-height: 1.5;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100px;
          gap: 12px;
        }
        .logistics-big-box {
          display: none;
          width: 30%;
          border: 1px solid;
          padding: 8px;
          // display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
        .logistics-big-box-child {
          height: 50%;
          width: 50%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          text-transform: uppercase;
          font-weight: bold;
        }
        .logistics-bus-container {
          background: white;
          padding: 10px;
          border-radius: 50%;
          height: 54px;
          display: flex;
          width: 54px;
          align-items: center;
          justify-content: center;
        }
        .logistics-box-header {
          color: white;
          justify-content: left;
          padding-left: 16px;
          flex-direction: row;
        }
        .logistics-best-offer-img {
          width: 22%;
        }
        .faq-emphasized {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
        .faq-section-title {
          font-weight: bold;
          color: #6f6f6f;
          font-size: 18px;
        }

        .faq-delivery-table {
          margin: auto;
        }
        .faq-delivery-table th {
          border: 1px solid black;
          padding: 16px;
          font-size: 14px;
          text-align: center;
          font-weight: normal;
        }
        .faq-delivery-table td {
          border: 1px solid black;
          padding: 16px;
          font-size: 14px;
          text-align: center;
        }
        .faq-table-note-td {
          width: 300px;
        }

        @media (max-width: 640px) {
          .about-container {
            width: 100%;
            overflow-x: auto;
          }
          .about-content {
            padding: 0 20px;
            margin: 10px;
            background: #fff;
            height: auto;
            margin-bottom: 70px;
            font-family: DomaineSansText-Regular !important;
          }
          .about-content p,
          .about-content li,
          .about-content {
            font-size: 13px !important;
            font-family: DomaineSansText-Regular;
          }
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
            font-family: DomaineSansText-Regular;
          }
          .lg-txt {
            font-size: 24px;
            line-height: 26px;
            font-family: DomaineSansText-Regular;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
          .ant-tabs-nav-list {
            overflow: unset !important;
            width: unset !important;
            -webkit-overflow-scrolling: touch;
            padding: 0 15px;
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin-left: 0;
          }
          .tabs {
            padding-left: 0;
            display: flex;
            flex-wrap: nowrap;
          }
          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
          .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
            justify-content: flex-start;
          }
          .txt-description {
            font-size: 13px !important;
            font-family: DomaineSansText-Regular !important;
          }

          .txt-faq-ans {
            padding: 16px;
            font-size: 14px;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            padding: 8px 0 !important;
          }
          .seller-faq-container {
            margin: 0 26px;
          }
          .seller-faq-bold-title {
            font-size: 24px;
            line-height: 31px;
          }
          .tabs {
            padding: 0px;
          }
          .about-content {
            padding: 0px;
          }
          .seller-faq-container {
            margin: 8px;
          }
          .seller-faq-bold-title {
            font-size: 16px;
            margin-bottom: 16px;
          }
          .collapse-container {
            padding: 10px 0;
          }
          .panel,
          .section-panel {
            width: auto;
          }
          .logistics-figure-section {
            gap: 14px;
            flex-direction: column;
          }
          .logistics-figure-section-left {
            gap: 14px;
            width: 100%;
          }
          .logistics-column-container {
            display: flex;
            gap: 14px;
            flex-direction: column;
            margin-top: 16px;
          }
          .logistics-mobile-box-container {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .logistics-box {
            width: 50%;
          }
          .logistics-box-header {
            width: 100%;
          }
          .logistics-big-box {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 24px;
          }
          .logistics-big-box-child {
            width: 25%;
            height: 120px;
          }

          .logistics-best-offer-img {
            width: 25%;
          }
          .ant-tabs-tab:first-child {
            margin-left: 10px;
          }
          .ant-tabs-tab:nth-last-child(2) {
            margin-right: 10px;
          }

          .faq-delivery-table th {
            padding: 8px;
            font-size: 12px;
          }
          .faq-delivery-table td {
            padding: 8px;
            font-size: 12px;
          }
          .faq-table-note-td {
            min-width: 190px;
          }
          .faq-section-title {
            font-size: 16px;
          }
          .about-container .ant-collapse-header {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default FAQ;
