/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { storeActionCreators } from "../../redux/stores";
import {
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Upload,
  Radio,
  Tooltip,
  Checkbox,
  DatePicker,
  Space,
  Table,
  Popconfirm,
} from "antd";
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  ArrowRightOutlined,
  DeleteFilled,
  CloseCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { RouterPrompt } from "./routerPrompt";
import PreviewLisitingModal from "./PreviewLisitingModal";
import HelpFeedback from "./HelpFeedback";
import { occasion, kidsOccasion } from "../../assets/occassions";
import weights from "../../assets/weights";
import { QuantityModal } from "../../components/modals";
import triggerNotification from "../../hooks/triggerNotification";
import colors from "../../assets/colors";
import sizes from "../../assets/sizes";

const { Option } = Select;

const CompleteListing = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);
  const imgRef4 = useRef(null);
  const imgUpload1 = useRef(null);
  const imgUpload2 = useRef(null);
  const imgUpload3 = useRef(null);
  const imgUpload4 = useRef(null);

  const [showQtyModal, setShowQtyModal] = useState(false);
  const [type, setType] = useState("");
  const [isDiscount, setDiscount] = useState(false);
  const [variant, setVariant] = useState([]);
  const [tableVariant, setTableVariant] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  const [categories, setCategories] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [category, setCategory] = useState([]);
  const [itemSizes, setItemSizes] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productCat, setProductCat] = useState({});
  const [kidsAgeRange, setKidsAgeRange] = useState([]);
  const [kidsCategory, setKidsCategory] = useState([]);
  const [kidsOccasions, setKidsOccasions] = useState([]);
  const [kidsSubCategory, setKidsSubCategory] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  const [itemFit, setItemFit] = useState([]);
  const [itemGender, setItemGender] = useState([]);
  const [productPreview, setProductPreview] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const { details, draft } = useSelector((state) => state.store);

  const { createDraft, deleteDraft, createProduct } = bindActionCreators(
    storeActionCreators,
    dispatch
  );

  const navigate = useNavigate();

  const columns = [
    {
      title: "Item name",
      dataIndex: "itemName",
      key: "name",
      width: 300,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{ color: "white", backgroundColor: "black" }}
        >
          {record.size}
        </div>
      ),
      width: 150,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <div className="color-container" style={{ height: "45px" }}>
          <div
            style={{ background: record.colorObj.hex }}
            className="color-hex-box"
          ></div>
          <div className="color-name">{record.colorObj.name}</div>
        </div>
      ),
      width: 250,
    },
    {
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "16px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            placeholder="Enter quantity"
            className="input"
            type="number"
            min={1}
            onChange={(e) => addQuantity(record, e.target.value)}
            style={{ height: "45px" }}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "key",
      render: (id) => (
        <Popconfirm
          title="Deletions cannot be undone, new sizes will need to be added if you change your mind. Confirm delete?"
          onConfirm={() => confirm(id)}
          okText="Confirm"
          cancelText="Cancel"
        >
          <DeleteFilled
            className="table-quantity-delete-icon"
            style={{ color: "red" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const mobileColumns = [
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{ color: "white", backgroundColor: "black" }}
        >
          {record.size}
        </div>
      ),
      width: 150,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{ width: "90px", height: "45px", padding: "4px" }}
        >
          <div
            style={{ background: record.colorObj.hex }}
            className="color-hex-box"
          ></div>
          <div className="color-name">{record.colorObj.name}</div>
        </div>
      ),
      width: 150,
    },
    {
      title: (
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "14px",
                marginRight: "0px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            // placeholder="Enter quantity"
            className="input"
            type="number"
            min={1}
            onChange={(e) => addQuantity(record, e.target.value)}
            style={{ height: "48px" }}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "key",
      render: (id) => (
        <Popconfirm
          title="Deletions cannot be undone, new sizes will need to be added if you change your mind. Confirm delete?"
          onConfirm={() => confirm(id)}
          okText="Confirm"
          cancelText="Cancel"
        >
          <DeleteFilled
            className="table-quantity-delete-icon"
            style={{ color: "red" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const uploadImg = async ({ file, onSuccess, onError }, ref, name) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "product-images");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload/`,
      data
    );
    if (result.status === 200) {
      const url = result.data.secure_url;

      setImgUrl([...imgUrl, { [name]: url }]);

      file.status = "success";
      ref.current.style.background = `url(${result.data.secure_url})`;
      ref.current.style.backgroundSize = "cover";
      ref.current.firstChild.style.display = "none";
      ref.current.lastChild.style.display = "none";
      onSuccess("ok");
    } else onError("error");
  };

  const onSubmitDraft = async (fieldsValue) => {
    form.setFieldsValue({
      sub_catid: subCategory.id,
      product_catid: productCat.id,
    });
    const data = {
      ...form.getFieldsValue(),
      discount_end_date: fieldsValue["discount_end_date"]?.format("YYYY-MM-DD"),
      discount_start_date:
        fieldsValue["discount_start_date"]?.format("YYYY-MM-DD"),
      product_id: draft.draft_id,
    };
    data.image1 = draft.image1 || imgUrl[0];
    data.image2 = draft.image2 || imgUrl[1];
    data.image3 = draft.image3 || imgUrl[2];
    data.image4 = draft.image4 || imgUrl[3];
    data.variety = Array.from(new Set([...draft.variety, ...variant]));
    data.currency = countryInfo?.local_currency;

    if (
      data?.sub_catid === 6 ||
      data?.sub_catid === 5 ||
      data?.sub_catid === 8 ||
      data?.sub_catid === 7
    ) {
      if (!data?.variety || data?.variety?.length < 1) {
        triggerNotification({
          type: "error",
          message: "Please select color and size",
        });

        return;
      }
      if (data?.variety) {
        for (let i = 0; i < data?.variety?.length; i++) {
          if (!data?.variety[i].quantity) {
            triggerNotification({
              type: "error",
              message: "Please select quantity",
            });
          }
        }
      }
    }

    const res = await createProduct(data);
    if (res.status === "ok") {
      navigate("/manage-listings");
      triggerNotification({
        type: "success",
        message: res.message,
      });
      deleteDraft([draft.draft_id]);
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const onSaveForLater = async () => {
    const { name } = form.getFieldValue();
    form.setFieldsValue({
      sub_catid: subCategory?.id,
      product_catid: productCat?.id,
    });
    const data = {
      ...form.getFieldsValue(),
      discount_end_date: undefined,
      discount_start_date: undefined,
      image1: imgUrl[0] || draft.image1,
      image2: imgUrl[1] || draft.image2,
      image3: imgUrl[2] || draft.image3,
      image4: imgUrl[3] || draft.image4,
      // variety: Array.from(new Set([...draft.variety, ...variant])),
      variety: variant || tableVariant,
      currency: countryInfo?.local_currency,
    };

    if (name === undefined) {
      return triggerNotification({
        type: "error",
        message: "Please enter at least item title",
      });
    }

    const res = await createDraft(data);

    if (res.status === "ok") {
      deleteDraft([draft.draft_id]);
      triggerNotification({
        type: "success",
        message: res.message,
      });
      navigate("/saved-listings");
    }
  };

  const onPreviewLisitng = () => {
    const { image1, image2, image3, image4 } = draft;
    const { discount_end_date, discount_start_date, price } =
      form.getFieldValue();
    const previewObject = {
      ...form.getFieldsValue(),
      price: Number(price),
      discount_end_date: discount_end_date?.format("YYYY-MM-DD"),
      discount_start_date: discount_start_date?.format("YYYY-MM-DD"),
      images: [image1, image2, image3, image4] || imgUrl,
      variant: variant,
      currency: countryInfo?.local_currency,
    };

    setProductPreview(previewObject);

    setPreviewModalVisible(true);
  };

  useEffect(() => {
    const { main_catid } = form.getFieldValue();
    form.setFieldsValue({
      main_catid: main_catid?.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountries = async () => {
    const result = await axios.get(
      "https://losode.dev/api/v2/country/currencies"
    );
    setCountryInfo(
      result.data.data.find((item) => item.country === details.storeCountry)
    );
  };

  const getCategories = async () => {
    const result = await axios.get("https://losode.dev/api/v2/all/categories");
    setCategories(result.data.data);
  };

  const loadSelectedCategory = (gender) => {
    if (gender === "1" || gender === 1) gender = "Men";
    if (gender === "2" || gender === 2) gender = "Women";
    if (gender === "3" || gender === 3) gender = "Kids";
    setCategory(categories[gender]?.categories);
    selectGroup(gender);
    setItemGender(gender);
  };
  const loadSubCategory = (id) => {
    const subCategory = category?.find((sub) => sub.id === Number.parseInt(id));
    setSubCategory(subCategory);
    form.setFieldsValue({ sub_catid: subCategory?.category });
    selectItemFit(subCategory?.category);
  };

  const loadItemSizes = (group) => {
    setItemSizes(sizes(itemGender, productCat, group, subCategory?.category));
  };

  const loadProductCat = (id) => {
    const cat = subCategory?.subs?.find(
      (sub) => sub.id === Number.parseInt(id)
    );
    form.setFieldsValue({ product_catid: cat?.category });
    setProductCat(cat);
  };

  const loadSelectedKidsGender = (cat) => {
    setKidsAgeRange(category.find((item) => item.category === cat).subs);
  };

  const loadSelectedKidCategory = (range) => {
    const category = kidsAgeRange.find((item) => item.category === range).subs;
    setKidsCategory(category);
    setKidsOccasions(kidsOccasion(range));
  };

  const loadSelectedKidSubCategory = (cat) => {
    const subCategory = kidsCategory.find((item) => item.category === cat).subs;
    setKidsSubCategory(subCategory);
  };

  const selectGroup = (gender) => {
    if (gender === "Men") setItemGroup(["Plus Size", "Tall", "Regular"]);
    if (gender === "Women")
      setItemGroup(["Plus Size", "Tall", "Petite", "Maternity", "Regular"]);
  };
  const selectItemFit = (cat) => {
    if (cat === "Clothing")
      setItemFit(["Loose Fit", "Regular Fit", "Tight Fit "]);
    if (cat === "Footwear")
      setItemFit(["Wide Fit", "Regular Fit", "Slim Fit "]);
  };

  const loadImages = () => {
    const { image1, image2, image3, image4 } = draft;
    let images = [image1, image2, image3, image4];
    const refs = [imgRef1, imgRef2, imgRef3, imgRef4];

    refs.map((ref, i) => {
      if (images[i] !== null && images[i] !== undefined) {
        ref.current.style.background = `url(${images[i]})`;
        ref.current.style.backgroundSize = "cover";
        ref.current.firstChild.style.display = "none";
        ref.current.lastChild.style.display = "none";
      }
      return true;
    });
  };

  const loadVariants = (item) => {
    item.variety?.map((val) => (val.itemName = item.name));
    setVariant(item.variety);
  };

  const addColor = (color) => {
    const currentColorList = selectedColors;
    if (selectedColors.length < 3) {
      const thisColorExists = currentColorList.find((e) => e.hex === color.hex);
      if (!thisColorExists) setSelectedColors(selectedColors.concat(color));
    }
  };

  const subtractColor = (color) => {
    const currentColorList = selectedColors;
    const newColorList = currentColorList.filter((e) => e.hex !== color.hex);

    setSelectedColors(newColorList);
  };

  const changeSize = (size) => {
    const sizeList = selectedSizes;

    if (size === "One Size") {
      setSelectedSizes(["One Size"]);
      return;
    }
    const hasSizeAlready = sizeList.includes(size);
    if (!hasSizeAlready) {
      const newSizeList = sizeList.filter((e) => e !== "One Size");
      setSelectedSizes(newSizeList.concat(size));
    }
    if (hasSizeAlready) setSelectedSizes(sizeList.filter((e) => e !== size));
  };

  const generateTable = () => {
    const data = [];
    if (selectedColors.length > 0 && selectedSizes.length > 0) {
      selectedColors.map((color) => {
        selectedSizes.map((size) => {
          data.push({
            key: data.length,
            itemName: form.getFieldValue("name"),
            colorObj: color,
            color: color.name,
            size,
          });
          return true;
        });
        return true;
      });
    }
    setTableVariant(data);
  };

  const confirm = (id) => {
    const data = tableVariant.filter((item) => item.key !== id);
    setTableVariant(data);
  };

  const addQuantity = (item, val) => {
    item.quantity = val;
  };

  useEffect(() => {
    generateTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColors, selectedSizes]);

  useEffect(() => {
    if (subCategory.category === "Fabrics") loadItemSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory?.category]);

  useEffect(() => {
    getCategories();
    getCountries();
    loadImages();
  }, []);

  useEffect(() => {
    const item = draft;
    loadSelectedCategory(item.main_catid);
    loadVariants(item);
    setType(item.product_type);
    setDiscount(item?.has_discount);
    item?.discounts_start_date &&
      form.setFieldValue(
        "discount_start_date",
        moment(item?.discounts_start_date)
      );
    item?.discounts_end_date &&
      form.setFieldValue("discount_end_date", moment(item?.discounts_end_date));
  }, [categories]);

  useEffect(() => {
    const item = draft;
    loadSubCategory(item.sub_catid);
  }, [category]);

  useEffect(() => {
    const item = draft;
    loadProductCat(item.product_catid);
  }, [subCategory]);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const disabledDate = (current) => {
    // Can not select days before today
    const date = new Date(Date.now());
    const prevTime = new Date(date.getTime());
    prevTime.setDate(date.getDate() - 1);
    return current && current < prevTime;
  };

  return (
    <div style={{ background: "#F9F9F9" }}>
      <RouterPrompt
        when={1}
        message="You have unsaved changes. Are you sure you want to leave this page?"
        title="Are you sure you want to leave?"
        cancelText="Confirm"
        okText="Cancel"
      />
      <div className="list-navbar">
        <img
          src="/images/losode-no-tagline.png"
          alt="logo"
          style={{ width: !isTabletOrMobile ? "120px" : "95px" }}
        />
        <div style={{ cursor: "pointer" }} onClick={() => navigate("/account")}>
          Your Seller Dashboard
        </div>
      </div>
      <div className="account-container">
        <Form
          form={form}
          name="listing"
          initialValues={draft}
          onFinish={onSubmitDraft}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
        >
          <Row
            className="form-row"
            gutter={[48, 12]}
            style={{
              background: "white",
              padding: !isTabletOrMobile ? "24px" : "16px",
            }}
          >
            <div>
              <h1 className="edit-listing-welcome-txt-title">
                Complete the saved listing
              </h1>
              <p className="edit-listing-welcome-txt-more">
                complete your saved items here. Your listing will be submitted
                for approval by our Seller Acquisition Team
              </p>
            </div>

            <Col span={24}>
              <Form.Item
                name="name"
                label="Item title"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input
                  className="input item-title-specific"
                  placeholder="Enter a descriptive title of the item you are listing e.g Sequined drawstring silk dress"
                  suffix={
                    <Tooltip title="Ensure your title is descriptive and add your brand name at the end. If your item has multiple colours do not use colour in your title e.g., ‘Black sequined dress’ or ‘Sequined drawstring silk dress’ ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="product_type"
                label="Ready to wear or Bespoke"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setType(e.target.value)}>
                  <Radio value="ready to wear">Ready to wear</Radio>
                  <Radio value="bespoke">Made to order</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {type === "bespoke" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Space>
                  <Form.Item
                    name="bespoke_duration"
                    label="How long will it take for the item to be made? "
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      style={{
                        width: "100%",
                        marginRight: "16px",
                        alignItems: "center",
                      }}
                      className="input"
                      placeholder="e.g. 5"
                      suffix={
                        <Tooltip title="Please note that customers are not usually prepared to wait longer than 10 days for items to be made">
                          <InfoCircleOutlined
                            style={{
                              color: "rgba(0,0,0,.45)",
                              fontSize: "20px",
                              marginRight: "20px",
                            }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <span style={{ fontSize: "18px" }}>days</span>
                </Space>
              </Col>
            )}
            {form?.getFieldValue("product_type") !== "bespoke" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="product_return_type"
                  label={
                    <div
                      style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <div>What is your returns policy?</div>
                      <Tooltip title="As much as we do not anticipate a high number of issues. It is important to give your buyer a level of comfort. Damaged items will be returned ">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            fontSize: "20px",
                            marginRight: "20px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Checkbox value="Returns">Refunds</Checkbox>
                    {/* <Checkbox value="Amendments">Amendments</Checkbox> */}
                    <Checkbox value="Exchanges">Exchanges</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                {/* <div style={{ marginTop: "-16px" }}>
                Please select at least one option
              </div> */}
              </Col>
            )}

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="main_catid"
                label="Who is the item made for? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Select
                  className="select"
                  onChange={loadSelectedCategory}
                  placeholder="Select an option"
                >
                  <Option value={"2"}>Women</Option>
                  <Option value={"1"}>Men</Option>
                  <Option value={"3"}>Kids</Option>
                </Select>
              </Form.Item>
            </Col>

            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_gender"
                  label="Tell us the gender of the child"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidsGender}
                    placeholder="Select an option"
                  >
                    {category.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_age"
                  label="Tell us the age range of the child"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidCategory}
                    placeholder="Select an option"
                  >
                    {kidsAgeRange.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_cat"
                  label="What is the category? "
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidSubCategory}
                    placeholder="Select an option"
                  >
                    {kidsCategory.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_sub_cat"
                  label="What is the sub-category? "
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    // onChange={loadSelectedKidSubCategory}
                    placeholder="Select an option"
                  >
                    {kidsSubCategory.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender !== "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="sub_catid"
                  label="What is the category?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select an option"
                    onChange={loadSubCategory}
                  >
                    {category?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender !== "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="product_catid"
                  label="What is the sub-category? "
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select an option"
                    // onSelect={(val, p) => setItemCat(p.children)}
                  >
                    {subCategory?.subs?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && subCategory?.category !== "Beauty" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="occassion_type"
                  label="What occasion is the item best suited for?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select occasion-select-box"
                    placeholder="Select an option"
                    mode="multiple"
                  >
                    {kidsOccasions?.map((item, idx) => (
                      <Option key={idx} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && subCategory?.category !== "Beauty" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="occassion_type"
                  label="What occasion is the item best suited for?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select occasion-select-box"
                    placeholder="Select an option"
                    mode="multiple"
                  >
                    {occasion.map((item, idx) => (
                      <Option key={idx} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && subCategory?.category !== "Beauty" && (
              <>
                {(subCategory?.category === "Clothing" ||
                  subCategory?.category === "Footwear") && (
                  <>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="size_and_fit"
                        label="What is the size fit? "
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                        >
                          {itemFit.map((item, idx) => (
                            <Option key={idx} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="group"
                        label="What is the body fit?"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                          onChange={loadItemSizes}
                        >
                          {itemGroup.map((group, idx) => (
                            <Option key={idx} value={group}>
                              {group}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}
            <Col span={24}>
              <Form.Item
                name="description"
                label="What is the description of the item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="input"
                  placeholder="e.g., Round neck black sequined dress that can be worn for a night out"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="material_info"
                label="How should buyers how to care for the item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="input"
                  placeholder="Add useful information on material composition and how to clean the item> e.g., 100% cotton, hand wash only"
                />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="price"
                label="How much is your item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Enter price"
                  type="number"
                  suffix={
                    <Tooltip title="Prices must be set in your local currency. If you want to set prices in a different currency, change your base location in Store settings">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            {variant.length === 0 &&
              (subCategory?.category === "Clothing" ||
                subCategory?.category === "Footwear" ||
                subCategory?.category === "Fabrics") && (
                <>
                  <Col span={24}>
                    <Form.Item label="Select the colour of your item and select two other colours this item is available in.">
                      {selectedColors.length > 0 && (
                        <div className="selected-colors-text-container">
                          <div className="selected-colors-text-container-title">
                            Create a new listing if you have more than three (3)
                            colours
                          </div>
                          <div className="selected-colors-container">
                            {selectedColors.map((color, index) => {
                              return (
                                <div
                                  className="selected-color-container"
                                  key={index}
                                >
                                  <div
                                    className="color-check-container"
                                    style={{
                                      borderColor:
                                        color.hex !== "#000000"
                                          ? color.hex
                                          : "white",
                                      backgroundColor: "black",
                                    }}
                                  >
                                    <CheckCircleFilled
                                      style={{
                                        color:
                                          color.hex !== "#000000"
                                            ? color.hex
                                            : "#383838",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                  <div className="color-name">{color.name}</div>
                                  <CloseCircleOutlined
                                    onClick={() => subtractColor(color)}
                                    style={{ fontSize: "16px" }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      <div className="all-colors-container">
                        {colors.map((color, index) => {
                          return (
                            <div
                              onClick={() => addColor(color)}
                              className="color-container"
                              key={index}
                            >
                              <div
                                style={{ background: color.hex }}
                                className="color-hex-box"
                              ></div>
                              <div className="color-name">{color.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={
                        <div>
                          Please select all sizes your item is available in. You
                          can select multiple sizes
                        </div>
                      }
                    >
                      <div className="sizes-section-container">
                        <div className="sizes-container">
                          {itemSizes?.map((size, index) => {
                            return (
                              <div
                                key={index}
                                className="size-contain"
                                onClick={() => changeSize(size.letter)}
                                style={{
                                  borderColor: selectedSizes.includes(
                                    size.letter
                                  )
                                    ? "black"
                                    : "#D4D4D4",
                                  borderWidth: selectedSizes.includes(
                                    size.letter
                                  )
                                    ? "2px"
                                    : "1px",
                                }}
                              >
                                <div
                                  className="size-letter"
                                  style={{
                                    backgroundColor: selectedSizes.includes(
                                      size.letter
                                    )
                                      ? "black"
                                      : "white",
                                    color: selectedSizes.includes(size.letter)
                                      ? "white"
                                      : "black",
                                  }}
                                >
                                  {size.letter}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </>
              )}
          </Row>

          <Row
            className="form-row"
            gutter={[48, 12]}
            style={{
              background: "white",
              padding: !isTabletOrMobile ? "24px" : "16px",
            }}
          >
            <Col span={24}>
              <Form.Item label="Upload Product Image (s)">
                <div className="product-image-section">
                  <div
                    className="product-image-contain-dropdown"
                    style={{ position: "relative" }}
                  >
                    <ImgCrop aspect={11 / 16}>
                      <Upload
                        customRequest={(file) =>
                          uploadImg(file, imgRef1, "image1")
                        }
                        showUploadList={false}
                        ref={imgUpload1}
                      >
                        <div className="product-big-image" ref={imgRef1}>
                          <PlusCircleOutlined className="product-image-upload-icon-big" />
                          <div className="product-image-upload-text-big">
                            Add Main Image
                          </div>
                        </div>
                      </Upload>
                    </ImgCrop>
                  </div>

                  <div className="product-small-images-container">
                    <div
                      className="product-image-contain-dropdown"
                      style={{ position: "relative" }}
                    >
                      <ImgCrop aspect={11 / 16}>
                        <Upload
                          customRequest={(file) =>
                            uploadImg(file, imgRef2, "image2")
                          }
                          showUploadList={false}
                          ref={imgUpload2}
                        >
                          <div className="product-small-image" ref={imgRef2}>
                            <PlusCircleOutlined className="product-image-upload-icon-small" />
                            <div className="product-image-upload-text-small">
                              Back View
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                    <div
                      className="product-image-contain-dropdown"
                      style={{ position: "relative" }}
                    >
                      <ImgCrop aspect={11 / 16}>
                        <Upload
                          customRequest={(file) =>
                            uploadImg(file, imgRef3, "image3")
                          }
                          showUploadList={false}
                          ref={imgUpload3}
                        >
                          <div className="product-small-image" ref={imgRef3}>
                            <PlusCircleOutlined className="product-image-upload-icon-small" />
                            <div className="product-image-upload-text-small">
                              Side View
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                    <div
                      className="product-image-contain-dropdown"
                      style={{ position: "relative" }}
                    >
                      <ImgCrop aspect={11 / 16}>
                        <Upload
                          customRequest={(file) =>
                            uploadImg(file, imgRef4, "image4")
                          }
                          showUploadList={false}
                          ref={imgUpload4}
                        >
                          <div className="product-small-image" ref={imgRef4}>
                            <PlusCircleOutlined className="product-image-upload-icon-small" />
                            <div className="product-image-upload-text-small">
                              Item Detail
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                  </div>
                </div>

                <div className="product-image-section-extra">
                  Use clear images taken with good lighting, you can add up to
                  four (4), we suggest that you add at least two (2) images to
                  make ‘buyers’ more comfortable with the item
                </div>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="has_discount"
                label={
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <div>Would you like to add a discount to your item? </div>
                    <Tooltip title="Giving your buyers a discount when you first open your store is a good idea and can drive a high volume of sales quickly ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "16px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setDiscount(e.target.value)}
                  value={isDiscount}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {isDiscount && (
              <>
                <Col span={!isTabletOrMobile ? 6 : 24}>
                  <Form.Item
                    name="discounts_percent"
                    label="Enter discount percentage"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      className="input"
                      style={{ width: "40%" }}
                      suffix={<span style={{ marginRight: 30 }}>%</span>}
                    />

                    {/* <div
                      className="product-image-section-extra"
                      style={{ marginTop: "16px" }}
                    >
                      Field should be numeric only
                    </div> */}
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 9 : 24}>
                  <Form.Item
                    name="discount_start_date"
                    label="When do you want the discount to start?"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      className="date"
                      placeholder="Select start date"
                    />
                  </Form.Item>
                  <Form.Item
                    name="discounts_start_type"
                    style={{ marginTop: "-16px" }}
                  >
                    <Checkbox value="immediately">
                      <span style={{ marginRight: "4px" }}>
                        Start immediately{" "}
                      </span>
                      <Tooltip title="The discount you have applied will start as soon as your listing is live">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            fontSize: "20px",
                            marginRight: "20px",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 9 : 24}>
                  <Form.Item
                    name="discount_end_date"
                    label="When do you want the discount to end?"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value > getFieldValue("discount_start_date")) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            "Please enter a valid time period!"
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      className="date"
                      placeholder="Select end date"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {/* </div> */}
          </Row>

          {variant.length > 0 ? (
            <Row
              className="form-row"
              gutter={[48, 12]}
              style={{
                background: "white",
                padding: !isTabletOrMobile ? "24px" : "16px",
              }}
            >
              <div
                style={{
                  padding: !isTabletOrMobile ? "24px" : "16px",
                  background: "white",
                  width: "100%",
                }}
              >
                <h4>
                  If you want to update your Item color size or quantity, kindly
                  click on the button below.
                </h4>

                <Button
                  className="btn-quantity-modal"
                  type="primary"
                  onClick={() => setShowQtyModal(true)}
                >
                  Update Quantity
                </Button>
              </div>
            </Row>
          ) : (
            <>
              {tableVariant.length > 0 && (
                <Row
                  className="form-row"
                  gutter={[48, 12]}
                  style={{
                    background: "white",
                    padding: !isTabletOrMobile ? "24px" : "16px",
                  }}
                >
                  <Col span={24}>
                    <Form.Item label="Based on what you have told us these are available options for your items, now tell us the quantity of each">
                      <Table
                        dataSource={tableVariant}
                        columns={!isTabletOrMobile ? columns : mobileColumns}
                        pagination={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}

          <Row className="form-row" gutter={[48, 12]}>
            <div
              style={{
                padding: !isTabletOrMobile ? "24px 24px 64px" : "16px",
                background: "white",
                width: "100%",
              }}
            >
              <Col span={24}>
                <div className="delivery-title">Delivery</div>
                <div className="delivery-box">
                  <div className="delivery-notification">
                    <InfoCircleOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        fontSize: "16px",
                        marginRight: "20px",
                      }}
                    />
                    We work with third-party delivery providers to handle your
                    delivery
                  </div>

                  {/* <Form.Item
                    name="delivery_type"
                    label={
                      <div>
                        <strong>How will this item be delivered?</strong>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                    style={{
                      width: !isTabletOrMobile ? "60%" : "100%",
                      marginBottom: "0px",
                    }}
                  >
                    
                    <Radio.Group
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                      }}
                      onChange={(e) => setSelfDeliveryInfo(e.target.value)}
                    >
                      <Radio value="self">Handle delivery yourself?</Radio>
                      <Radio value="losode">Losode handles delivery?</Radio>
                      <Radio value="pay">
                        You pay for delivery (free to the buyer)?
                      </Radio>
                    </Radio.Group>
                  </Form.Item> */}
                  <div
                    style={{
                      width: !isTabletOrMobile ? "40%" : "100%",
                      marginBottom: "0px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="product_weight"
                        label={
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <strong>Tell us the weight of your item</strong>
                            <Tooltip title="Ensure you have entered the correct weight to avoid additional charges">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "16px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select occasion-select-box"
                          placeholder="Select an option"
                          style={{ width: "80%" }}
                        >
                          {weights(
                            subCategory?.category,
                            subCategory?.slug
                          )?.map((item) => (
                            <Option key={item.id} value={item.KG}>
                              {item.KG}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <span> Kg </span>
                    </div>

                    <div className="weight-extra-text">
                      Please provide accurate weights for your item to avoid
                      losing a sale.  Delivery fees are automatically calculated
                      based on the total weight of all items in a buyer’s
                      basket.  Inaccurate weights may result in high delivery
                      estimates and may cause buyers to abandon their basket.
                    </div>
                  </div>
                </div>
              </Col>
            </div>

            <div
              style={{
                padding: !isTabletOrMobile ? "0" : "16px",
                width: "100%",
                display: !isTabletOrMobile ? "flex" : "block",
              }}
            >
              <Col span={!isTabletOrMobile ? 8 : 24}>
                <Form.Item>
                  <Button
                    className="edit-listing-welcome-btn-submit"
                    htmlType="submit"
                    icon={<ArrowRightOutlined />}
                  >
                    Submit item for listing
                  </Button>
                </Form.Item>
              </Col>
              <Col span={!isTabletOrMobile ? 8 : 24}>
                <Form.Item>
                  <Button
                    className="edit-listing-welcome-btn-preview"
                    onClick={() => onPreviewLisitng()}
                    icon={<ArrowRightOutlined />}
                  >
                    Preview
                  </Button>
                </Form.Item>
              </Col>
              <Col span={!isTabletOrMobile ? 8 : 24}>
                <Form.Item>
                  <Button
                    className="edit-listing-welcome-btn-later"
                    // htmlType="submit"
                    icon={<ArrowRightOutlined />}
                    onClick={() => onSaveForLater()}
                  >
                    Save for later
                  </Button>
                </Form.Item>
              </Col>
            </div>

            <Col span={24}>
              <div className="list-item-submit-terms">
                By selecting submit for listing, you agree to the Losode Seller
                agreement and acknowledge reading our Privacy Policy. You assume
                full responsibility for the item offered and the content of your
                listing
              </div>
            </Col>
          </Row>
        </Form>
        <HelpFeedback />
      </div>

      <QuantityModal
        visible={showQtyModal}
        setVisible={setShowQtyModal}
        product={{ ...draft, product_id: draft?.draft_id }}
        type="draft"
      />

      <PreviewLisitingModal
        product={productPreview}
        modalVisible={previewModalVisible}
        setModalVisible={setPreviewModalVisible}
      />

      <style jsx="true">{`
        .dropdown-icon {
          display: none;
          top: 5%;
          right: 5%;
        }
        .product-image-contain-dropdown:hover .dropdown-icon {
          display: block;
        }

        .ant-modal-footer > button:nth-child(2) {
          background: black;
          color: white;
          height: 47px;
          padding: 0 30px;
          font-size: 16px;
          border: 4px;
          font-family: "DomaineSansText-Light";
        }
        .ant-modal-footer > button:nth-child(1) {
          background: white;
          color: black;
          height: 47px;
          padding: 0 30px;
          font-size: 16px;
          border: 4px;
          font-family: "DomaineSansText-Light";
        }
        .list-navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-family: "DomaineSansText-Light";
          padding: 20px 40px;
          background: white;
        }
        .all-colors-container {
          display: flex;
          flex-wrap: wrap;
          gap: 11px;
          border: 1px solid black;
          padding: 11px;
          justify-content: flex-start;
          height: 135px;
          font-family: "DomaineSansText-Light";
          overflow: auto;
        }
        .selected-colors-text-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 11px;
          border: 1px solid black;
          padding: 11px 15px;
          border-bottom: 0;
          font-family: "DomaineSansText-Light";
        }
        .selected-colors-container {
          display: flex;
          gap: 16px;
          font-family: "DomaineSansText-Light";
        }
        .color-container {
          padding: 8px;
          border: 1px solid black;
          width: 120px;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          white-space: nowrap;
          cursor: pointer;
        }
        .selected-color-container {
          padding: 8px;
          border: 1px solid black;
          width: 145px;
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: center;
          white-space: nowrap;
          background: black;
          color: white;
        }
        .color-hex-box {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
        .color-check-container {
          border: 1px solid;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          text-align: center;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .color-container-extra {
          font-size: 13px;
          margin-top: 8px;
        }

        .sizes-section-container {
          display: flex;
          gap: 11px;
          width: 70%;
        }
        .sizes-container {
          display: flex;
          flex-wrap: wrap;
          gap: 11px;
        }
        .size-contain {
          border: 1px solid black;
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 11px;
          align-items: center;
          cursor: pointer;
        }
        .one-size {
          border: 1px solid black;
          width: 90px;
          text-align: center;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .size-letter,
        .size-number {
          border: 1px solid black;
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
        .one-size-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .one-size-extra {
          font-size: 13px;
          margin-top: 11px;
        }

        .product-image-section {
          display: flex;
          gap: 16px;
          padding: 16px;
          border: 1px solid black;
          width: fit-content;
        }
        .product-big-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1.5px dashed;
          background-color: #fff5f8;
          width: 300px;
          height: 300px;
        }
        .product-small-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1.5px dashed;
          background-color: #fff5f8;
          width: 92px;
          height: 92px;
          text-align: center;
        }
        .product-small-images-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: space-between;
        }
        .product-image-upload-icon-big {
          font-size: 30px;
          margin-bottom: 16px;
        }
        .product-image-upload-icon-small {
          font-size: 15px;
          margin-bottom: 8px;
        }
        .product-image-upload-text-small {
          font-size: 10px;
        }
        .product-image-section-extra {
          font-size: 13px;
          margin-top: 8px;
        }
        .table-quantity-container {
          display: flex;
          align-items: center;
          gap: 20px;
          width: 200px;
        }
        .table-quantity-delete-icon {
          cursor: pointer;
        }

        textarea.ant-input {
          padding-top: 16px;
          padding-left: 16px;
        }

        .weight-extra-text {
          background: black;
          color: white;
          padding: 11px;
          margin-top: 30px;
        }
        .weight-number-box {
          display: flex;
          gap: 20px;
          align-items: center;
          border: 1px solid black;
          width: fit-content;
          padding: 11px;
        }
        .weight-number-section {
          display: flex;
          gap: 11px;
          align-items: center;
        }
        .delivery-box {
          display: flex;
          border: 1px solid rgb(111 111 111 / 29%);
          padding: 25px;
          justify-content: space-between;
          gap: 70px;
        }
        .delivery-title {
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: bold;
        }
        .edit-listing-welcome-btn-preview,
        .edit-listing-welcome-btn-later {
          background: white;
          font-size: 18px;
          height: 56px;
          width: 274px;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }
        .edit-listing-welcome-btn-preview,
        .edit-listing-welcome-btn-later,
        .edit-listing-welcome-btn-submit {
          margin-top: 32px;
        }
        .help-banner {
          position: fixed;
          background: black;
          color: white;
          padding: 10px;
          top: 70%;
          right: 0;
          cursor: pointer;
        }

        .account-container {
          font-family: DomaineSansText-Light;
          padding: 3% 10%;
        }
        .edit-listing-welcome-txt-title {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          padding: 0 24px;
        }
        .edit-listing-welcome-txt-more {
          font: normal normal 13px DomaineSansText-Light;
          color: black;
          padding: 0 24px;
          margin-bottom: 24px;
        }

        .edit-listing-welcome-txt-about {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .edit-listing-welcome-txt-info {
          font: normal normal 14px DomaineSansText-Light;
          color: black;
          width: 70%;
          text-align: center;
          margin: 8px auto;
        }

        .ant-form-vertical .ant-form-item-label > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .form-row {
          margin-top: 26.52px;
        }
        .input {
          height: 56px;
          border: 1px solid black;
          padding: 4px 0px 4px 14px;
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker,
        .ant-select-selector {
          height: 56px !important;
          width: 100%;
          border: 1px solid black;
        }
        .ant-picker-input > input,
        .ant-checkbox-group {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select,
        .ant-input-affix-wrapper > input.ant-input,
        span.ant-radio + * {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-radio-group {
          display: flex;
          align-items: center;
        }
        .ant-radio-inner {
          width: 20px;
          height: 20px;
        }
        .ant-radio-wrapper {
          margin-right: 40px;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid black;
          height: 56px;
          padding: 0px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 14px;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #800000;
          color: #fff;
          height: 56px;
          width: 151px;
        }
        .edit-listing-welcome-btn-submit {
          font-size: 18px;
          height: 56px;
          background: black;
          width: 274px;
          color: white;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }
        .ant-popover-inner-content {
          font-family: "DomaineSansText-Light";
        }
        .item-title-specific input.ant-input {
          text-transform: capitalize;
        }

        .btn-quantity-modal {
          font-size: 16px;
          margin-top: 35px;
          width: 265px;
          height: 50px;
        }

        @media (max-width: 640px) {
          .account-container {
            padding: 0;
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .form-row {
            row-gap: 7px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 40px;
            width: 86px;
            margin: 3px;
            padding: 5px;
          }
          .btn-upload span {
            line-height: 1.2;
          }
          .btn-upload img {
            height: 16px;
          }
          .btn-quantity-modal {
          }

          .btn-submit {
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }

          .list-navbar {
            font-size: 16px;
            padding: 16px;
          }
          .input,
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker,
          .ant-select-selector {
            height: 48px !important;
            margin-top: 0 !important;
            font-size: 13px !important;
          }
          .ant-select,
          .ant-input-affix-wrapper > input.ant-input,
          span.ant-radio + * {
            font-size: 13px !important;
          }
          .ant-form-item-explain-error {
            margin-top: 8px;
            font-size: 12px;
            margin-bottom: 8px;
          }
          .ant-form-vertical .ant-form-item-label > label,
          .ant-form label,
          .ant-input:placeholder-shown,
          .ant-picker-input > input:placeholder-shown {
            font-size: 13px !important;
          }
          .product-image-section {
            flex-direction: column;
          }
          .product-image-section .ant-upload,
          .product-image-section span,
          .product-big-image {
            // width: 100%;
          }
          .product-small-images-container {
            flex-direction: row;
          }
          .delivery-box {
            gap: 24px;
            flex-direction: column;
            padding: 0;
            border: 0;
          }
          .delivery-title,
          .edit-listing-welcome-txt-title {
            font-size: 18px;
          }
          .weight-extra-text {
            font-size: 13px;
          }
          .edit-listing-welcome-btn-preview,
          .edit-listing-welcome-btn-later,
          .edit-listing-welcome-btn-submit {
            width: 100%;
            justify-content: center;
            gap: 16px;
            font-size: 16px;
          }
          .edit-listing-welcome-btn-preview,
          .edit-listing-welcome-btn-later,
          .edit-listing-welcome-btn-submit {
            margin-top: 0px;
            margin-bottom: 24px;
          }
          .list-item-submit-terms {
            padding: 0 16px;
            font-size: 13px;
            margin-bottom: 24px;
          }
          .ant-select-arrow {
            top: 50%;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            line-height: 1.6;
          }
          .color-container {
            font-size: 13px;
            width: 48%;
          }
          .selected-color-container {
            width: 47%;
            font-size: 13px;
            height: fit-content;
          }
          .selected-colors-container {
            gap: 11px;
            flex-wrap: wrap;
          }
          .selected-colors-text-container-title {
            font-size: 13px;
          }
          .size-contain {
            width: 31%;
          }
          .size-letter,
          .size-number {
            font-size: 13px;
            height: fit-content;
            padding: 6px;
          }
          .sizes-section-container {
            width: 100%;
          }
          .table-quantity-container {
            width: auto;
          }
          .occasion-select-box .ant-select-selector {
            height: fit-content !important;
            padding: 4px;
          }
        }
      `}</style>
    </div>
  );
};

export default CompleteListing;
